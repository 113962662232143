import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import StyledTableCell from '../../HelperFunctions/mui/StyledTableCell';
import StyledTableRow from '../../HelperFunctions/mui/StyledTableRow';
import { useDispatch, useSelector } from 'react-redux';
import { shiftsOfToday } from '../../../actions/reportAction';
import { getWarehouse } from '../../../actions/warehouseActions';
import { useTranslation } from 'react-i18next';
import { extractTime, calcDifferenceTime } from '../../../utils/DateTime';
import CountTime from '../../HelperFunctions/CountTime';

import { Button } from '@mui/material';

function createData(id, name, online, done, total) {
  return { id, name, online, done, total };
}

export default function SummaryShifts() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let summary;
  const [filterID, setFilterID] = React.useState();
  const [filterStatus, setFilterStatus] = React.useState('all');
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    dispatch(shiftsOfToday());
    dispatch(getWarehouse());
  }, [dispatch]);

  let shifts = useSelector((state) => state.report?.TodayShifts);
  let warehouses = useSelector((state) => state.warehouses);

  const getWarehouseNameByID = (warehouseID, warehouses) => {
    let warehouse = false;
    if (warehouses && warehouses.length !== 0) {
      warehouse = warehouses.find((e) => e.id === warehouseID);
      return warehouse.name;
    }
    return null;
  };

  let shiftsToday = false;
  if (shifts && shifts.length !== 0) {
    if (filterStatus === 'online') {
      shiftsToday = shifts.filter((e) => e.is_working === true);
    } else if (filterStatus === 'done') {
      shiftsToday = shifts.filter((e) => e.is_working === false);
    } else {
      shiftsToday = shifts;
    }
    if (filterID) {
      shiftsToday = shiftsToday.filter(
        (e) => e.warehouse === parseInt(filterID)
      );
    }
  }

  const rows = [];

  if (shifts && shifts.length !== 0) {
    let grouped = {};
    shifts?.forEach((shift) => {
      let warehouse = shift.warehouse;
      if (warehouse in grouped) {
        grouped[warehouse].total++;
        if (shift.is_working) {
          grouped[warehouse].online++;
        } else {
          grouped[warehouse].done++;
        }
      } else {
        grouped[warehouse] = {
          id: shift.warehouse,
          name: getWarehouseNameByID(warehouse, warehouses),
          total: 1,
          online: shift.is_working ? 1 : 0,
          done: shift.is_working ? 0 : 1,
        };
      }
    });

    let ssummary = {
      online: 0,
      done: 0,
      total: 0,
    };
    // loop grouped by key
    for (let key in grouped) {
      rows.push(
        createData(
          grouped[key].id,
          grouped[key].name,
          grouped[key].online,
          grouped[key].done,
          grouped[key].total
        )
      );
      ssummary = {
        online: ssummary.online + grouped[key].online,
        done: ssummary.done + grouped[key].done,
        total: ssummary.total + grouped[key].total,
      };
    }

    if (!summary) {
      summary = ssummary;
    }
  }

  const handleFilter = (status, id) => {
    setFilterID(id);
    setFilterStatus(status);
    setShow(true);
  };

  const updateCurrent = () => {
    dispatch(shiftsOfToday());
  };

  const latestTask = (tasks) => {
    return tasks.find((e) => e.end === null) || tasks[tasks.length - 1] || null;
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-4">
        <Button variant="outlined" color="error" onClick={updateCurrent}>
          {t('common.update')}
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="Summary shifts of today">
          <TableHead>
            <TableRow>
              <StyledTableCell
                onClick={() => handleFilter('all')}
                className="cursor-pointer"
                sx={
                  show &&
                  filterStatus === 'all' && {
                    backgroundColor: '#ef5350!important',
                  }
                }
              >
                {t('report.warehouse')}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                onClick={() => handleFilter('online')}
                className="cursor-pointer"
                sx={
                  show &&
                  filterStatus === 'online' && {
                    backgroundColor: '#ef5350!important',
                  }
                }
              >
                Online: {summary?.online}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                onClick={() => handleFilter('done')}
                className="cursor-pointer"
                sx={
                  show &&
                  filterStatus === 'done' && {
                    backgroundColor: '#ef5350!important',
                  }
                }
              >
                Done: {summary?.done}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                onClick={() => handleFilter('all')}
                className="cursor-pointer"
                sx={
                  show &&
                  filterStatus === 'all' && {
                    backgroundColor: '#ef5350!important',
                  }
                }
              >
                {t('report.total')} : {summary?.total}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  onClick={() => handleFilter('all', row.id)}
                  className="cursor-pointer"
                  sx={row.id === filterID && { backgroundColor: '#ef5350' }}
                >
                  {row.name}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  onClick={() => handleFilter('online', row.id)}
                  className="cursor-pointer"
                  sx={
                    row.id === filterID &&
                    filterStatus === 'online' && { backgroundColor: '#ef5350' }
                  }
                >
                  {row.online}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  onClick={() => handleFilter('done', row.id)}
                  className="cursor-pointer"
                  sx={
                    row.id === filterID &&
                    filterStatus === 'done' && { backgroundColor: '#ef5350' }
                  }
                >
                  {row.done}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  onClick={() => handleFilter('all', row.id)}
                  className="cursor-pointer"
                  sx={
                    row.id === filterID &&
                    filterStatus === 'all' && { backgroundColor: '#ef5350' }
                  }
                >
                  {row.total}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {show && filterStatus === 'online' && (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table aria-label="shifts">
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('report.employee')}</StyledTableCell>
                <StyledTableCell align="center">
                  {t('report.principal')}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t('report.workType')}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t('report.startWorking')}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t('report.workingTime')}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shiftsToday &&
                shiftsToday?.reverse().map((shift) => (
                  <StyledTableRow key={shift.id}>
                    <StyledTableCell component="th" scope="row">
                      {shift.worker_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {latestTask(shift.tasks)?.principal_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {latestTask(shift.tasks)?.work_type_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {extractTime(shift.check_in)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CountTime startTime={Date.parse(shift.check_in)} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {show && filterStatus === 'done' && (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table aria-label="shifts">
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('report.employee')}</StyledTableCell>
                <StyledTableCell align="center">
                  {t('report.warehouse')}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t('report.checkInTime')}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t('report.checkOutTime')}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t('report.workingTime')}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shiftsToday &&
                shiftsToday?.reverse().map((shift) => (
                  <StyledTableRow key={shift.id}>
                    <StyledTableCell component="th" scope="row">
                      {shift.worker_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {getWarehouseNameByID(shift.warehouse, warehouses)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {extractTime(shift.check_in)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {extractTime(shift.check_out)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {calcDifferenceTime(shift.check_in, shift.check_out)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {show && shiftsToday && filterStatus === 'all' && (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table aria-label="shifts">
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('report.employee')}</StyledTableCell>
                <StyledTableCell align="center">
                  {t('report.principal')}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t('report.workType')}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t('report.checkInTime')}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t('report.checkOutTime')}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t('report.workingTime')}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shiftsToday &&
                shiftsToday?.reverse().map((shift) => (
                  <StyledTableRow key={shift.id}>
                    <StyledTableCell component="th" scope="row">
                      {shift.worker_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {latestTask(shift.tasks)?.principal_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {latestTask(shift.tasks)?.work_type_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {extractTime(shift.check_in)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                    {shift.check_out ? extractTime(shift.check_out): ""}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {shift.check_out ? (
                        calcDifferenceTime(shift.check_in, shift.check_out)
                      ) : (
                        <CountTime startTime={Date.parse(shift.check_in)} />
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
