import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Modal } from 'react-bootstrap';
import { Button, TextField } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const EndShiftModal = ({ open, handleClose, handleSubmitModal, unit }) => {
  const { t } = useTranslation();
  const [amouth, setAmouth] = React.useState();

  const handleSumit = () => {
    let val = amouth;
    if (!val) {
      val = 0;
    }
    handleSubmitModal(val);
  };

  return (
    <Modal show={open} onHide={handleClose} centered>
      <Modal.Header closeButton />

      <Modal.Body>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {unit === 'calendar'
            ? t('timer.performanceCalendar')
            : t('timer.performanceCalendarBox')}
        </Typography>

        <Typography variant="subtitle1" component="h3" className="mb-3">
          <b>{t('timer.performanceCalendarDescription')}</b>
        </Typography>

        <TextField
          fullWidth
          id="outlined-basic"
          label="Calendar"
          type="number"
          variant="outlined"
          InputProps={{ inputProps: { min: 0 } }}
          value={amouth}
          onChange={(e) => setAmouth(e.target.value)}
        />
        <Row className="my-3 ">
          <Col className="d-flex justify-content-between">
            <Button onClick={handleSumit} variant="contained" type="submit">
              {t('common.send')}
            </Button>
            <Button onClick={handleClose} variant="outlined">
              {t('common.cancel')}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default EndShiftModal;
