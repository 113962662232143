import { Autocomplete, Checkbox, TextField } from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreateShiftEmployee = ({ users, onChaneEmployee }) => {
  return (
    <Autocomplete
      sx={{ py: 2.5, px: 3 }}
      multiple
      id="checkboxes-employees"
      options={users.sort(
        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
      )}
      onChange={(event, newValue) => {
        onChaneEmployee(newValue);
      }}
      groupBy={(option) => option.firstLetter}
      disableCloseOnSelect
      getOptionLabel={(option) => option.fullName}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.fullName}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="employees" placeholder="employees" />
      )}
    />
  );
};

export default CreateShiftEmployee;
