import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getSickLeaveReasons,
  deleteSickLeaveReason,
} from '../../../../api/statistics';
import { useEffect } from 'react';
import { ListItem, ListItemText, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../../../components/confirm-dialog/ConfirmDialog';

const filter = createFilterOptions();

export default function ReasonAutocomplete({ onChange, currentValue }) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const query = useQuery(['sickleaveReasons'], getSickLeaveReasons, {
    staleTime: Infinity,
  });

  const deleteReason = useMutation(
    ['sickleaveReasons'],
    deleteSickLeaveReason,
    {
      onSuccess: () => {
        query.refetch();
      },
    }
  );

  const options = query.data || [];

  const handleChange = (event, newValue) => {
    let isExisting = false;
    if (typeof newValue === 'string') {
      isExisting = options.find(
        (option) =>
          newValue?.toLocaleLowerCase() === option.name.toLocaleLowerCase()
      );
    }
    if (isExisting || newValue?.id) {
      if (newValue?.id) {
        setValue(newValue);
      } else {
        setValue(isExisting);
      }
    } else if (typeof newValue === 'string' && isExisting) {
      setValue({
        name: newValue,
      });
    } else {
      setValue(newValue);
    }
  };

  const handleDelete = () => {
    deleteReason.mutateAsync(value.id);
    setOpenConfirm(false);
    setValue(null);
  };

  useEffect(() => {
    if (value) {
      onChange(value);
    } else {
      onChange(inputValue);
    }
  }, [onChange, value, inputValue]);

  return (
    <>
      <Autocomplete
        value={value}
        onChange={handleChange}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          const isExisting = options.some(
            (option) => inputValue === option.name
          );
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              name: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        handleHomeEndKeys
        autoHighlight
        id="sickleave-reason"
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        renderOption={(props, option) => (
          <ListItem
            {...props}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => setOpenConfirm(true)}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText primary={option.name} />
          </ListItem>
        )}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => setInputValue(e.target.value)}
            label="Reason"
          />
        )}
      />

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete
          </Button>
        }
      />
    </>
  );
}
