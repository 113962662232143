import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import EnhancedTableHead from "../../../HelperFunctions/mui/EnhancedTableHead";
import useSortHandler from "../../../HelperFunctions/mui/useSortHandler";
import usePagesHandler from "../../../HelperFunctions/mui/usePagesHandler";
import EnhancedTableBody from "../../../HelperFunctions/mui/EnhancedTableBody";
import { getUsers } from "../../../../actions/userActions";
import { Form, Row, Col } from "react-bootstrap";

const headCells = [
  {
    id: "id",
    numeric: false,
    label: "ID",
  },
  {
    id: "last_name",
    numeric: false,
    label: "Last Name",
  },
  {
    id: "first_name",
    numeric: false,
    label: "First Name",
  },
  {
    id: "email",
    numeric: false,
    label: "Email",
  },
  {
    id: "user_type",
    numeric: false,
    label: "Role",
  },
  {
    id: "total_work_hours",
    numeric: false,
    label: "Total Work Hours",
  },
  {
    id: "annual_work_hours",
    numeric: false,
    label: "Annual Work Hours",
  },
];

const bodyCells = {
  key: "id",
  names: [
    "id",
    "last_name",
    "first_name",
    "email",
    "user_type",
    "total_work_hours",
    "annual_work_hours",
  ],
};

export default function EmployeeList() {
  const dispatch = useDispatch();
  const { order, orderBy, handleRequestSort } = useSortHandler("asc", "id");
  const { page, handleChangePage, rowsPerPage, handleChangeRowsPerPage } =
    usePagesHandler(0, 10);

  const [searchResult, setSearchResult] = React.useState([]);
  const [keyWord, setKeyWord] = React.useState("");

  let users = useSelector((state) => state.users);

  const handleSearchChange = (searchTerm) => {
    setKeyWord(searchTerm);
    handleChangePage(null, 0);
    if (searchTerm !== "") {
      const filteredProductList = users.filter((product) => {
        return Object.values(product)
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchResult(filteredProductList);
    } else {
      setSearchResult(users);
    }
  };

  let rows = [];

  React.useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  React.useEffect(() => {
    setSearchResult(users);
  }, [users]);

  if (searchResult) {
    rows = searchResult;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Row>
          <Col>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Rivit per sivu:"
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className="pagination"
            />
          </Col>
          <Col className="d-flex align-items-center">
            <Form.Control
              name="search"
              type="text"
              placeholder="Search..."
              value={keyWord}
              onChange={(e) => handleSearchChange(e.target.value)}
              className="mr-4"
            />
          </Col>
        </Row>

        <TableContainer>
          <Table aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <EnhancedTableBody
              rows={rows}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              bodyCells={bodyCells}
            />
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
