import CountTime from '../../HelperFunctions/CountTime';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  addSpeedRate,
  stopWork,
  switchWork,
} from '../../../actions/shiftActions';
import ReactSwipeButton from '../../Others/ReactSwipeButton';
import { useTranslation } from 'react-i18next';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getPrincipalsTasks } from '../../../actions/warehouseActions';
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { postShiftTask } from '../../../actions/shiftActions';
import EndShiftModal from './EndShiftModal';

const InJob = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const shifts = useSelector((state) => state.shift);
  const tasks = useSelector((state) => state.tasks);
  const principalTasks = useSelector((state) => state.principalTasks);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [usingModal, setUsingModal] = useState(false);
  const [unit, setUnit] = useState('');

  let latestTask = tasks.find((task) => task.end === null); // latest tasks
  const competedTasks = tasks.filter((task) => task.end !== null);

  const [latestShift, setLatestShift] = useState({});

  useEffect(() => {
    const x = shifts.find(
      (shift) => shift.check_out === null && shift.is_working && true
    );
    setLatestShift(x);
  }, [competedTasks, shifts, latestShift]);

  useEffect(() => {
    dispatch(
      getPrincipalsTasks(latestShift.warehouse, latestTask.principal_id)
    );
  }, [dispatch, latestShift, latestTask, shifts]);

  const start = Date.parse(latestTask.start);
  const breakTask = principalTasks?.find(
    (tasks) => tasks.description === 'BREAK-BUTTON'
  );

  useEffect(() => {
    let uni = '';

    const filtered = [];

    tasks.forEach((task) => {
      let ta = principalTasks?.find((t) => t.id === task.work_type);
      if (ta) {
        filtered.push(ta);
      }
    });

    const x = filtered?.find((tasks) => {
      const descriptions = tasks.description.split(',');
      uni = descriptions.find((e) => e.includes('UNIT'));

      return descriptions?.find(
        (description) => description === 'PERFORMANCE-END'
      );
    });
    if (uni) {
      setUnit(uni.split(':')[1].trim());
    }
    if (x) {
      setUsingModal(true);
    }
  }, [principalTasks, tasks, usingModal]);

  const switchWorking = async (event) => {
    setLoading(true);
    await dispatch(
      switchWork(latestShift.id, latestTask.id, latestTask.work_type)
    );
    setLoading(false);
  };

  const stopWorking = async (event) => {
    setLoading(true);
    await dispatch(
      switchWork(latestShift.id, latestTask.id, latestTask.work_type)
    );
    await dispatch(stopWork(latestShift.id));
    navigate('/');
  };

  const startBreak = async () => {
    setLoading(true);
    await dispatch(
      switchWork(latestShift.id, latestTask.id, latestTask.work_type)
    );
    await dispatch(postShiftTask(latestShift.id, breakTask.id));
    setLoading(false);
  };

  const contineuWorking = async (event) => {
    setLoading(true);
    const competedTasksNoBreak = competedTasks.filter(
      (task) => task.work_type !== breakTask.id
    );
    competedTasksNoBreak.sort((a, b) => {
      return Date.parse(a.end) - Date.parse(b.end);
    });
    const previousTask = competedTasksNoBreak[competedTasksNoBreak.length - 1];
    await dispatch(
      switchWork(latestShift.id, latestTask.id, latestTask.work_type)
    );
    await dispatch(postShiftTask(latestShift.id, previousTask.work_type));
    setLoading(false);
  };

  const getTime = (timestamp) => {
    const dateObject = new Date(timestamp);
    const hours = ('0' + dateObject.getHours()).substr(-2);
    const minutes = ('0' + dateObject.getMinutes()).substr(-2);

    const result = `${hours}:${minutes}`;
    return result;
  };

  const getWorkedTime = () => {
    let sumTimeStamp = 0;
    competedTasks.forEach((task) => {
      const diff = Date.parse(task.end) - Date.parse(task.start);
      sumTimeStamp += diff;
    });
    return sumTimeStamp;
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
  };

  const handleSubmitModal = async (amouth) => {
    setOpen(false);
    setLoading(true);

    await dispatch(
      switchWork(latestShift.id, latestTask.id, latestTask.work_type)
    );
    await dispatch(stopWork(latestShift.id));
    await dispatch(addSpeedRate(latestShift.id, amouth, unit));
    navigate('/');
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  return (
    <div className="text-center mt-2">
      <div className="pt-5">
        <h4 className="border rounded p-3 text-green">
          <div className="row">
            <div className="col-lg-3">
              {latestTask.principal_name}, {t(latestTask.work_type_name)}
            </div>
            <div className="col-lg-6">
              <div>
                <CountTime startTime={start} />
              </div>
              {breakTask && breakTask.id !== latestTask.work_type && (
                <div className="my-3">
                  <LoadingButton
                    onClick={startBreak}
                    loading={loading}
                    loadingIndicator="Loading..."
                    variant="contained"
                  >
                    {t('timer.startBreak')}
                  </LoadingButton>
                </div>
              )}
              {breakTask && breakTask.id === latestTask.work_type && (
                <div className="my-3">
                  <LoadingButton
                    onClick={contineuWorking}
                    loading={loading}
                    loadingIndicator="Loading..."
                    variant="contained"
                  >
                    {t('timer.continueWorking')}
                  </LoadingButton>
                </div>
              )}
            </div>
            <div className="col-lg-3 mt-2">
              <ReactSwipeButton
                text={t('timer.switchWorkType')}
                color="#358dc9"
                onSuccess={switchWorking}
              />
            </div>
          </div>
        </h4>
        <h4 className="border rounded p-3 text-red">
          <div className="row">
            <div className="col-lg-3">{t('timer.totalWorkingTime')}</div>
            <div className="col-lg-6">
              <CountTime startTime={start - getWorkedTime()} />
            </div>
            <div className="col-lg-3 mt-2">
              <ReactSwipeButton
                text={t('timer.stopWorking')}
                color="#de0E19"
                onSuccess={usingModal ? handleOpenModal : stopWorking}
              />
            </div>
          </div>
        </h4>
        <hr />

        {competedTasks &&
          competedTasks.reverse().map((task) => (
            <h4 key={task.id} className="border rounded p-3">
              <div className="row">
                <div className="col-lg-3">
                  {task.principal_name}, {task.work_type_name}
                </div>
                <div className="col-lg-6">{`${getTime(
                  Date.parse(task.start)
                )} - ${getTime(Date.parse(task.end))}`}</div>
              </div>
            </h4>
          ))}
      </div>
      <EndShiftModal
        open={open}
        handleClose={handleClose}
        handleSubmitModal={handleSubmitModal}
        unit={unit}
      />
    </div>
  );
};
export default InJob;
