import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/custom.scss';
import 'video-react/dist/video-react.css';
import App from './App';
import store from './store.js';
import { Provider } from 'react-redux';
import './i18n';
import { SnackbarProvider } from 'notistack';
import { Slide } from '@mui/material';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ThemeProvider from './theme';

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              TransitionComponent={Slide}
            >
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </Router>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);
