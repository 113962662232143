export const getWarehouseNameByID = (warehouseID, warehouses) => {
  if (warehouseID === 'All') return 'All';
  if (warehouseID === 'SICK') return 'Sairasloma';
  const id = parseInt(warehouseID);
  const warehouse = warehouses.find((e) => e.id === id);
  return warehouse.name;
};

export const getWorkerNameByID = (workerID, workers) => {
  if (typeof workerID === 'string') {
    workerID = parseInt(workerID);
  }
  const worker = workers.find((e) => e.id === workerID);
  const fullName = `${worker.first_name} ${worker.last_name}`;
  return fullName;
};
