import React, { useState } from "react";

import RenderReportSection from "./Internal/RenderReportSection";
import SearchSection from "./Internal/SearchSection";

const InternalReport = () => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <SearchSection setLoading={setLoading}/>
      <RenderReportSection loading={loading} setLoading={setLoading}/>
    </>
  );
};

export default InternalReport;
