export const setMessage = (message, type, time) => {
    return async dispatch => {
        dispatch({
            type: 'SET_MESSAGE',
            message: [message, type],
        })
        setTimeout(() => {
            dispatch({
                type: 'SET_MESSAGE',
                message: null,
            })
        }, time*1000)
    }
}