import { Link } from "react-router-dom";
import reportlogo from "../../../assets/images/report.svg";
import workericon from "../../../assets/images/Worker-online-2.png";

const ExternalManagerTools = () => {
  return (
    <div className="my-2 row">
      <div className="col-6 col-lg-4 mt-2">
        <Link to="tools/reports">
          <div className="pt-2 text-center">
            <div className="icon-color">
              <img
                src={reportlogo}
                alt="reoprt logo"
                width="120px"
                height="120px"
              />
            </div>
            <div className="card-body">
              <h5 className="card-title">Raportit ja Statistiikat</h5>
            </div>
          </div>
        </Link>
      </div>

      <div className="col-6 col-lg-4 mt-2">
        <Link to="tools/workingStatus">
          <div className="pt-2 text-center">
            <div className="icon-color">
              <img
                src={workericon}
                alt="avg kerroin logo"
                width="120px"
                height="120px"
              />
            </div>
            <div className="card-body">
              <h5 className="card-title">Live Työ Status</h5>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ExternalManagerTools;
