export const getCheckIn = (check_in, assigned_start) => {
  if (!assigned_start) {
    return check_in;
  }

  if (Date.parse(assigned_start) > Date.parse(check_in)) {
    return assigned_start;
  }

  return check_in;
};

export const getCheckOut = (check_out, assigned_end, is_extra) => {
  if (is_extra || !assigned_end) {
    return check_out;
  }

  if (Date.parse(assigned_end) < Date.parse(check_out)) {
    return assigned_end;
  }

  return check_out;
};
