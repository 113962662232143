import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const { REACT_APP_BACKEND_URL } = process.env;
const baseUrl = `${REACT_APP_BACKEND_URL}`;

const searchShifts = async (data) => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };
  const response = await axios.get(
    `${baseUrl}/report/shift/?is_working=${data.is_working}&check_in=${data.startDate}&check_out=${data.endDate}&warehouse=${data.warehouse}&worker=${data.worker}`,
    config
  );
  return response.data;
};

const warehouseShares = async () => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };
  const response = await axios.get(`${baseUrl}/report/shift/`, config);
  return response.data;
};

const getWorkingShift = async () => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };
  const response = await axios.get(
    `${baseUrl}/report/shift/?is_working=true`,
    config
  );
  return response.data;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default { searchShifts, warehouseShares, getWorkingShift };
