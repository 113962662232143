import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getWorkingShift } from '../../../actions/shiftActions';
import { getUsers } from '../../../actions/userActions';
import { Table } from 'react-bootstrap';
import { getWarehouse } from '../../../actions/warehouseActions';
import CountTime from '../../HelperFunctions/CountTime';
import { convertIsoToLocalDate, extractTime } from '../../../utils/DateTime';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CurrentWorking = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filterID, setFilterID] = useState(false);
  useEffect(() => {
    dispatch(getWorkingShift());
    dispatch(getUsers());
    dispatch(getWarehouse());
  }, [dispatch]);

  let shifts = useSelector((state) => state.shift);
  let warehouses = useSelector((state) => state.warehouses);

  let CurrentWorking = false;
  if (shifts && shifts.length !== 0) {
    CurrentWorking = shifts.filter((e) => e.is_working === true);
    if (filterID) {
      CurrentWorking = shifts.filter((e) => e.warehouse === parseInt(filterID));
    }
  }

  const getWarehouseNameByID = (warehouseID, warehouses) => {
    let warehouse = false;
    if (warehouses && warehouses.length !== 0) {
      warehouse = warehouses.find((e) => e.id === warehouseID);
      return warehouse.name;
    }
    return null;
  };

  const groupWarehouses = (shifts) => {
    let grouped = {};
    shifts?.forEach((shift) => {
      let warehouse = shift.warehouse;
      if (warehouse in grouped) {
        grouped[warehouse].total++;
      } else {
        grouped[warehouse] = {
          name: getWarehouseNameByID(warehouse, warehouses),
          total: 1,
        };
      }
    });
    return grouped;
  };

  let groupedWarehouses = false;
  let groupedWarehousesElement;
  if (warehouses) {
    groupedWarehouses = groupWarehouses(shifts);
    groupedWarehousesElement = Object.keys(groupedWarehouses).map((id) => (
      <div className="col-lg-4" key={groupedWarehouses[id].name}>
        <p onClick={() => setFilterID(id)} className="cursor-pointer">
          {groupedWarehouses[id].name}: {groupedWarehouses[id].total}
        </p>
      </div>
    ));
  }

  const updateCurrent = () => {
    dispatch(getWorkingShift());
  };

  return (
    <div>
      <div className="row mt-5">
        <div className="col-12 d-flex justify-content-between">
          <p>
            <b>
              {t('report.total')} {shifts && shifts.length}
            </b>
          </p>
          <Button onClick={updateCurrent}>{t('common.update')}</Button>
        </div>
      </div>
      <div className="row mt-2">
        {groupedWarehouses && groupedWarehousesElement}
      </div>
      {filterID && (
        <div className="mt-3">
          <Table striped bordered hover className="h7">
            <thead>
              <tr>
                <th>{t('report.employee')}</th>
                <th>{t('report.principal')}</th>
                <th>{t('report.workType')}</th>
                <th>{t('report.startWorking')}</th>
                <th>{t('report.workingTime')}</th>
              </tr>
            </thead>
            <tbody>
              {CurrentWorking &&
                CurrentWorking.map((shift) => (
                  <tr key={shift.id}>
                    <td>{shift.worker_name}</td>
                    <td>{shift.tasks.principal_name}</td>
                    <td>{t(shift.tasks.work_type_name)}</td>
                    <td>
                      {convertIsoToLocalDate(shift.check_in)}{' '}
                      {extractTime(shift.check_in)}
                    </td>
                    <td>
                      <CountTime startTime={Date.parse(shift.check_in)} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default CurrentWorking;
