import { useState } from "react";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const onSubmit = async (event) => {
    event.preventDefault();
    // TODO
  };
  return (
    <div className="p-5 rounded border col-lg-6 col-md-8 col-sm-12">
      <div className="mb-5">
        <h4>Forgot password?</h4>
      </div>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email Address *</label>
          <input
            required
            className="form-control"
            value={email}
            name="email"
            type="email"
            onChange={({ target }) => setEmail(target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;
