import { useState } from 'react';
import { TableRow, TableCell } from '@mui/material';

import { fDate } from '../../../../utils/formatTime';
import Label from '../../../../components/label';

import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import ImageDialog from '../../../../components/image-dialog';

const ReportTableRow = ({ row, onDeleteRow }) => {
  const { start_date, end_date, status, days, filename } = row;

  const [openImage, setOpenImage] = useState(false);

  const [image, setImage] = useState(null);

  const storage = getStorage();

  const handleViewRow = async () => {
    setOpenImage(true);
    const x = await getDownloadURL(ref(storage, `sickleave/${filename}`));
    setImage(x);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const getLabel = (status) => {
    return (
      <Label
        variant="soft"
        color={
          status === 'accepted'
            ? 'success'
            : status === 'rejected'
            ? 'error'
            : 'info'
        }
      >
        {status}
      </Label>
    );
  };

  return (
    <>
      <TableRow hover onClick={handleViewRow} className="cursor-pointer">
        <TableCell align="center">{fDate(start_date)}</TableCell>
        <TableCell align="center">{fDate(end_date)}</TableCell>
        <TableCell align="center">{days}</TableCell>
        <TableCell align="center">{getLabel(status)}</TableCell>
      </TableRow>

      <ImageDialog open={openImage} onClose={handleCloseImage} image={image} />
    </>
  );
};

export default ReportTableRow;
