import axios from 'axios';
import Cookies from 'universal-cookie';
import { shiftsCollectionRef, speedRateCollectionRef } from '../db/firestore';
import { addDoc } from 'firebase/firestore';

const cookies = new Cookies();
const { REACT_APP_BACKEND_URL } = process.env;
const baseUrl = `${REACT_APP_BACKEND_URL}/shift/`;

const getShift = async () => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };
  const response = await axios.get(`${baseUrl}`, config);
  return response.data;
};

const getWorkingShift = async () => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };
  const response = await axios.get(`${baseUrl}?is_working=true`, config);
  return response.data;
};

const getShiftByWorkerID = async (id) => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };
  const response = await axios.get(`${baseUrl}?worker=${id}`, config);
  return response.data;
};

const postShift = async (worker, warehouse, time, is_working) => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };

  const data = {
    worker,
    check_in: time,
    is_working,
    warehouse,
  };

  await addDoc(shiftsCollectionRef, data);
  const response = await axios.post(`${baseUrl}`, data, config);
  return response.data;
};

const postSpeedRate = async (data) => {
  await addDoc(speedRateCollectionRef, data);
  return;
};

const createShift = async (worker, warehouse, assignedStart, assignedEnd) => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };

  const data = {
    worker,
    assigned_start: assignedStart,
    assigned_end: assignedEnd,
    warehouse,
  };

  const response = await axios.post(`${baseUrl}`, data, config);
  return response.data;
};

const getShiftTask = async (id) => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };

  const response = await axios.get(`${baseUrl}${id}/task/`, config);
  return response.data;
};

const patchShift = async (id, data) => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };
  const response = await axios.patch(`${baseUrl}${id}/`, data, config);
  return response.data;
};

const postShiftTask = async (warehouseID, workType, time) => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };

  const data = {
    work_type: workType,
    start: time,
  };

  const response = await axios.post(
    `${baseUrl}${warehouseID}/task/`,
    data,
    config
  );
  return response.data;
};

const putShiftTask = async (inputdata) => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };

  const data = {
    work_type: inputdata.workType,
    end: inputdata.time,
  };

  const response = await axios.put(
    `${baseUrl}${inputdata.shiftID}/task/${inputdata.taskID}/`,
    data,
    config
  );
  return response.data;
};

// eslint-disable-next-line
export default {
  getShift,
  postShift,
  postSpeedRate,
  createShift,
  getShiftTask,
  postShiftTask,
  putShiftTask,
  patchShift,
  getShiftByWorkerID,
  getWorkingShift,
};
