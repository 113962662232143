import { Link } from 'react-router-dom';
import reportlogo from '../../../assets/images/report.svg';
import keyicon from '../../../assets/images/key.svg';
import workericon from '../../../assets/images/Worker-online-2.png';
import { FcFilingCabinet } from 'react-icons/fc';
import { FaFileMedical } from 'react-icons/fa';

import { useTranslation } from 'react-i18next';

const Tools = () => {
  const { t } = useTranslation();
  return (
    <div className="my-2 row">
      <div className="col-6 col-lg-4 mt-2">
        <Link to="/tools/reports">
          <div className="pt-2 text-center">
            <div className="icon-color">
              <img
                src={reportlogo}
                alt="reoprt logo"
                width="120px"
                height="120px"
              />
            </div>
            <div className="card-body">
              <h5 className="card-title">
                {t('navigation.reportAndStatistics')}
              </h5>
            </div>
          </div>
        </Link>
      </div>

      <div className="col-6 col-lg-4 mt-2">
        <Link to="/tools/create-shifts">
          <div className="pt-2 text-center">
            <div className="icon-color">
              <img
                src={keyicon}
                alt="avg kerroin logo"
                width="120px"
                height="120px"
              />
            </div>
            <div className="card-body">
              <h5 className="card-title">{t('navigation.createShifts')}</h5>
            </div>
          </div>
        </Link>
      </div>

      <div className="col-6 col-lg-4 mt-2">
        <Link to="/tools/workingStatus">
          <div className="pt-2 text-center">
            <div className="icon-color">
              <img
                src={workericon}
                alt="avg kerroin logo"
                width="120px"
                height="120px"
              />
            </div>
            <div className="card-body">
              <h5 className="card-title">{t('navigation.liveStatistics')}</h5>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-6 col-lg-4 mt-2">
        <Link to="/sickleave/list">
          <div className="pt-2 text-center">
            <div className="icon-color">
              <FaFileMedical size={120} />
            </div>
            <div className="card-body">
              <h5 className="card-title">{t('navigation.sickleave-list')}</h5>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-6 col-lg-4 mt-2">
        <Link to="/tools/inventory-list">
          <div className="pt-2 text-center">
            <div className="icon-color">
              <FcFilingCabinet size={120} color="red" />
            </div>
            <div className="card-body">
              <h5 className="card-title">{t('navigation.inventory-list')}</h5>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Tools;
