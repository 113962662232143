import { lazy } from 'react';

import Guides from '../components/Dashboard/Guides';
import Applicant from '../components/Dashboard/Home/Applicant';
import Home from '../components/Dashboard/Home/Internal';
import ExternalManager from '../components/Dashboard/Home/Staff';
import Staff from '../components/Dashboard/Home/Staff';
import EmSearch from '../components/Dashboard/Report/ExternalManager/EmSearch';
import ExternalManagerReport
  from '../components/Dashboard/Report/ExternalManagerReport';
import InternalReport from '../components/Dashboard/Report/InternalReport';
//import WorkerPermission from "../components/Dashboard/Tools/WorkerPermission";
import EmployeeList from '../components/Dashboard/Report/Manager/EmployeeList';
import Print from '../components/Dashboard/Report/Manager/Print';
import Search from '../components/Dashboard/Report/Manager/Search';
import ManagerReport from '../components/Dashboard/Report/ManagerReport';
import ChangePassword from '../components/Dashboard/Setting/ChangePassword';
//import Create from '../components/Dashboard/Create/Create'
//import Report from "../components/Dashboard/Report/InternalReport";
//import Calendar from "../components/Dashboard/Calendar/Calendar";
//import Freelancer from "../components/Dashboard/Home/Freelancer";
//import Extratime from "../components/Dashboard/ExtraTime/Extratime";
//import Absence from '../components/Dashboard/Absence/Absence'
import Timer from '../components/Dashboard/Timer/Timer';
import CreateShifts from '../components/Dashboard/Tools/CreateShifts';
import EmWorksStatus from '../components/Dashboard/Tools/EmWorksStatus';
import ExternalManagerTools
  from '../components/Dashboard/Tools/ExternalManagerTools';
import Tools from '../components/Dashboard/Tools/Tools';
import WorksStatus from '../components/Dashboard/Tools/WorksStatus';
import InventoryProductList from '../pages/dashboard/InventoryProductList';
import OffDay from '../pages/dashboard/offDay/index';
import SafetyPage from '../pages/dashboard/safety/index';
import AboutApp from '../pages/dashboard/Setting/AboutApp';
import Sickleave from '../pages/dashboard/tools/sickleave/Sickleave';
import SickLeaveListPage
  from '../pages/dashboard/tools/sickleave/SickLeaveList';

const Statistics = lazy(() =>
  import('../components/Dashboard/Report/Manager/Statistics')
);
const EmStatistics = lazy(() =>
  import('../components/Dashboard/Report/ExternalManager/EmStatistics')
);
const BillingReport = lazy(() =>
  import('../components/Dashboard/Report/Manager/BillingReports/BillingReport')
);
const Setting = lazy(() => import('../components/Dashboard/Setting/Setting'));

export const ExternalPermission = [
  { path: '/', displayText: 'Etusivu', component: <Home />, display: true },
  {
    path: '/timer',
    displayText: 'Työn aloitus',
    component: <Timer />,
    display: true,
  },
  {
    path: '/report',
    displayText: 'Raportit',
    component: <InternalReport />,
    display: true,
  },
  {
    path: '/setting',
    displayText: 'Asetukset',
    component: <Setting />,
    display: true,
  },
  {
    path: '/setting/change-password',
    displayText: 'vaihda salasana',
    component: <ChangePassword />,
  },
];

export const InternalPermission = [
  { path: '/', displayText: 'homepage', component: <Home />, display: true },
  {
    path: '/timer',
    displayText: 'stamp',
    component: <Timer />,
    display: true,
  },
  {
    path: '/report',
    displayText: 'reports',
    component: <InternalReport />,
    display: true,
  },
  {
    path: '/setting',
    displayText: 'setting',
    component: <Setting />,
    display: true,
  },
  {
    path: '/setting/change-password',
    displayText: 'change-password',
    component: <ChangePassword />,
  },
  {
    path: '/user-guide',
    displayText: 'instructions',
    component: <Guides />,
    display: true,
  },
  { path: '/sickleave', displayText: 'sickleave', component: <Sickleave /> },
  {
    path: '/off-day',
    displayText: 'off-day',
    component: <OffDay />,
  },
];

export const ManagerPermission = [
  { path: '/', displayText: 'homepage', component: <Staff />, display: true },
  {
    path: '/timer',
    displayText: 'stamp',
    component: <Timer />,
    display: true,
  },
  { path: '/tools', displayText: 'tools', component: <Tools />, display: true },
  {
    path: '/tools/workingstatus',
    displayText: 'Työ status',
    component: <WorksStatus />,
  },
  {
    path: '/tools/inventory-list',
    displayText: 'inventory-list',
    component: <InventoryProductList />,
  },
  {
    path: '/tools/reports',
    displayText: 'Raportit',
    component: <ManagerReport />,
  },
  { path: '/tools/reports/search', displayText: 'Haku', component: <Search /> },
  {
    path: '/tools/reports/bill',
    displayText: 'Billing Report',
    component: <BillingReport />,
  },
  {
    path: '/tools/reports/print',
    displayText: 'printReport',
    component: <Print />,
  },
  {
    path: '/tools/reports/employees',
    displayText: 'listOfEmployees',
    component: <EmployeeList />,
  },
  {
    path: '/tools/reports/statistics',
    displayText: 'marketShare',
    component: <Statistics />,
  },
  {
    path: '/tools/create-shifts',
    displayText: 'luoTyövuorot',
    component: <CreateShifts />,
  },
  { path: '/reports', displayText: 'Raportit', component: <ManagerReport /> },
  {
    path: '/setting',
    displayText: 'setting',
    component: <Setting />,
    display: true,
  },
  {
    path: '/setting/change-password',
    displayText: 'vaihda salasana',
    component: <ChangePassword />,
  },
  {
    path: '/setting/about',
    displayText: 'Tietoa',
    component: <AboutApp />,
  },
  {
    path: '/user-guide',
    displayText: 'instructions',
    component: <Guides />,
    display: true,
  },
  { path: '/sickleave', displayText: 'sickleave', component: <Sickleave /> },
  {
    path: '/sickleave/list',
    displayText: 'sickleave',
    component: <SickLeaveListPage />,
  },
  {
    path: '/safety',
    displayText: 'safety',
    component: <SafetyPage />,
  }
];

export const ExternalManagerPermission = [
  {
    path: '/',
    displayText: 'Etusivu',
    component: <ExternalManager />,
    display: true,
  },
  {
    path: '/timer',
    displayText: 'Työn aloitus',
    component: <Timer />,
    display: true,
  },
  {
    path: '/tools',
    displayText: 'Työkalut',
    component: <ExternalManagerTools />,
    display: true,
  },
  {
    path: '/tools/reports/statistics',
    displayText: 'marketShare',
    component: <EmStatistics />,
  },
  {
    path: '/tools/workingstatus',
    displayText: 'Työ status',
    component: <EmWorksStatus />,
  },
  {
    path: '/tools/reports',
    displayText: 'Raportit',
    component: <ExternalManagerReport />,
  },
  {
    path: '/tools/reports/search',
    displayText: 'Haku',
    component: <EmSearch />,
  },
  { path: '/reports', displayText: 'Raportit', component: <ManagerReport /> },
  {
    path: '/setting',
    displayText: 'Asetukset',
    component: <Setting />,
    display: true,
  },
  {
    path: '/setting/change-password',
    displayText: 'vaihda salasana',
    component: <ChangePassword />,
  },
];

export const ApplicantPermission = [
  { path: '/', displayText: 'Etusivu', component: <Applicant /> },
];
