import { compareAsc, format } from 'date-fns';

export function isDate(str) {
  const spitedstr = str.split('.');

  if (
    spitedstr.length === 3 &&
    !isNaN(parseInt(spitedstr[0])) &&
    !isNaN(parseInt(spitedstr[1])) &&
    !isNaN(parseInt(spitedstr[2]))
  ) {
    return true;
  }
  return false;
}

export function sortDate(dates) {
  const formated = [];

  if (dates.length < 2) return dates;

  dates.forEach((element) => {
    let spitedstr = element.split('.');
    if (spitedstr[0].length < spitedstr[spitedstr.length - 1].length) {
      spitedstr = spitedstr.reverse();
    }
    formated.push(new Date(spitedstr));
  });
  const sorted = formated.sort(compareAsc);

  const strSorted = sorted.map((d) => {
    return format(d, 'dd.MM.yyyy');
  });

  return strSorted;
}

export const formatSearchingDate = (startDate, endDate) => {
  let startDateReversed = '';
  if (startDate) {
    // spit startDate to year, month, day
    const startDateArr = startDate.split('-');
    // reverse startDate to day, month, year
    startDateReversed = `${startDateArr[2]}.${startDateArr[1]}.${startDateArr[0]}`;
  } else {
    startDateReversed = '';
  }

  // if endDate is not null
  let endDateReversed = '';
  if (endDate) {
    // spit endDate to year, month, day
    const endDateArr = endDate.split('-');
    // reverse endDate to day, month, year
    endDateReversed = `${endDateArr[2]}.${endDateArr[1]}.${endDateArr[0]}`;
  } else {
    // endDateReverved is today
    endDateReversed = `${new Date().getDate()}.${
      new Date().getMonth() + 1
    }.${new Date().getFullYear()}`;
  }
  return `${startDateReversed} - ${endDateReversed}`;
};
