import axios from "axios";
const { REACT_APP_BACKEND_URL } = process.env;
const baseUrl = `${REACT_APP_BACKEND_URL}/accounts`;

const getToken = async (credentials) => {
  const response = await axios.post(`${baseUrl}/login/`, credentials);
  return response.data;
};

const getUserInfo = async (token) => {
  const config = {
    headers: { Authorization: token },
  };
  const response = await axios.get(`${baseUrl}/user/`, config);
  return response.data;
};

const getUsers = async (token) => {
  const config = {
    headers: { Authorization: token },
  };
  const response = await axios.get(`${baseUrl}/list/`, config);
  return response.data;
};

const createUser = async (body) => {
  const response = await axios.post(`${baseUrl}/registration/`, body.user);
  return response;
};

const updateUserPassword = async (token, data) => {
  const config = {
    headers: { Authorization: token },
  };
  let response;
  try {
    response = await axios.post(`${baseUrl}/password/change/`, data, config);
  } catch (err) {
    response = err.response;
  }

  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getToken,
  getUserInfo,
  createUser,
  getUsers,
  updateUserPassword,
};
