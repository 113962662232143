import { useState } from "react";

export default function useSortHandler(defaultOrder, defaultOrderBy) {
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);

  function handleRequestSort(event, property) {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }
  return { order, orderBy, handleRequestSort };
}
