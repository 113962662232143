import PropTypes from 'prop-types';
// @mui
import {
  Stack,
  Avatar,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import renderContent from './renderContent';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// components
import Iconify from '../../../../components/iconify';
import { fToNow } from '../../../../utils/formatTime';
import { readedNotification } from '../../../../api/notification';
import { useTranslation } from 'react-i18next';

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.node,
    type: PropTypes.string,
    title: PropTypes.string,
    isUnRead: PropTypes.bool,
    description: PropTypes.string,
    createdAt: PropTypes.object,
  }),
  onClosePopover: PropTypes.func,
};

export default function NotificationItem({ notification, onClosePopover }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const queryClient = useQueryClient();

  const { avatar, title } = renderContent(notification, t, i18n);

  const mutation = useMutation(readedNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries(['notification']);
    },
  });

  const handleOpenNotification = () => {
    onClosePopover();
    mutation.mutate(notification.id);
    if (notification.link_to) {
      navigate(`/${notification.link_to}/`);
    }
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.isRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={handleOpenNotification}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Stack
            direction="row"
            sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}
          >
            <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
            <Typography variant="caption">
              {fToNow(notification.createdAt)}
            </Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
}
