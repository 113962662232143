import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  FaUserClock,
  FaClipboardList,
  FaBriefcaseMedical,
} from 'react-icons/fa';
import { BsFillGearFill } from 'react-icons/bs';
import { IoMdAnalytics } from 'react-icons/io';
import { IconContext } from 'react-icons';
import { BiNotepad } from 'react-icons/bi';
import Rules from './Rules';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Home = () => {
  const [shown, setShown] = useState(false);
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  return (
    <div className="my-2 row">
      <IconContext.Provider value={{ color: '#de0e19', size: '120' }}>
        <div className="col-6 col-lg-4 mt-2">
          <Link to="/timer">
            <div className="pt-2 text-center">
              <div className="icon-color">
                <FaUserClock />
              </div>
              <div className="card-body">
                <h5 className="card-title">{t('navigation.stamp')}</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-6 col-lg-4 mt-2">
          <Link to="/report">
            <div className="pt-2 text-center">
              <div className="icon-color">
                <IoMdAnalytics />
              </div>
              <div className="card-body">
                <h5 className="card-title">{t('navigation.reports')}</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-6 col-lg-4 mt-2">
          <Link to="/sickleave">
            <div className="pt-2 text-center">
              <div className="icon-color">
                <FaBriefcaseMedical />
              </div>
              <div className="card-body">
                <h5 className="card-title">{t('navigation.sickleave')}</h5>
              </div>
            </div>
          </Link>
        </div>

        {(user.id === 17 || user.id === 23) && (
          <div className="col-6 col-lg-4 mt-2">
            <Link to="/off-day">
              <div className="pt-4 text-center">
                <div className="icon-color">
                  <BiNotepad />
                </div>
                <div className="card-body">
                  <h5 className="card-title">Holiday</h5>
                </div>
              </div>
            </Link>
          </div>
        )}

        <div className="col-6 col-lg-4 mt-2">
          <Link to="/setting">
            <div className="pt-4 text-center">
              <div className="icon-color">
                <BsFillGearFill />
              </div>
              <div className="card-body">
                <h5 className="card-title">{t('navigation.setting')}</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-6 col-lg-4 mt-2">
          <Link to="" onClick={() => setShown(true)}>
            <div className="pt-4 text-center">
              <div className="icon-color">
                <FaClipboardList />
              </div>
              <div className="card-body">
                <h5 className="card-title">{t('navigation.rules')}</h5>
              </div>
            </div>
          </Link>
        </div>
      </IconContext.Provider>
      {shown && <Rules setShown={setShown} show={shown} />}
    </div>
  );
};

export default Home;
