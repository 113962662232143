import InJob from "./InJob";
import TaskForm from "./TaskForm";
import LoginToWorkPlace from "./LoginToWorkPlace";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getShiftTask,
  getShiftByWorkerID,
} from "../../../actions/shiftActions";

const Timer = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  let assigned_shift = null;

  useEffect(() => {
    dispatch(getShiftByWorkerID(user.id));
  }, [dispatch, user]);

  const shift = useSelector((state) => state.shift);
  let inWorkPlace = false;
  let found = false;

  if (shift && shift.length !== 0) {
    found = shift.find((e) => e.is_working === true);
    // for loop shift
    for (let i = 0; i < shift.length; i++) {
      if (shift[i].assigned_start !== null) {
        let hasAssigned;
        const assigned_start = new Date(shift[i].assigned_start).getTime();
        const assigned_end = new Date(shift[i].assigned_end).getTime();
        const now = new Date().getTime();
        // if now is between assigned_start and assigned_end
        if (now > assigned_start && now < assigned_end) {
          hasAssigned = true;
        }

        // if assigned_start is not null and assigned_start is before now under 60 minutes or after now under 60 minutes
        // then set hasAssigned to true

        const diffMinutes = Math.round(
          Math.abs((now - assigned_start) / 60000)
        );

        if (assigned_start !== null && diffMinutes < 60 && now < assigned_end) {
          hasAssigned = true;
        }

        if (hasAssigned) {
          assigned_shift = shift[i];
        }
      }
    }
  }

  if (found) {
    inWorkPlace = true;
  }

  useEffect(() => {
    if (inWorkPlace) {
      dispatch(getShiftTask(found.id));
    }
  }, [dispatch, found, inWorkPlace]);
  let inTask = false;
  let foundinTask = false;
  const tasks = useSelector((state) => state.tasks);

  if (tasks && tasks.length !== 0) {
    foundinTask = tasks.find((e) => e.end === null);
  }

  if (foundinTask) {
    inTask = true;
  }

  if (inWorkPlace && inTask) {
    return <InJob />;
  } else if (inWorkPlace && !inTask) {
    return <TaskForm />;
  } else {
    return <LoginToWorkPlace assigned={assigned_shift} />;
  }
};

export default Timer;
