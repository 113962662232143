import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Autocomplete,
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateDates } from '../../../../api/sickleave';
import { useSelector } from 'react-redux';
import ReasonAutocomplete from './ReasonAutocomplete';
import { createSickLeaveReason } from '../../../../api/statistics';

const SickleaveEditDialog = ({
  id,
  open,
  onClose,
  startDate,
  endDate,
  employee,
  sickReason,
  ...other
}) => {
  const queryClient = useQueryClient();

  const [start, setStart] = useState(new Date(startDate));
  const [end, setEnd] = useState(new Date(endDate));
  const [user, setUser] = useState(employee);
  const [reason, setReason] = useState(null);
  const users = useSelector((state) => state.users);

  const mutation = useMutation(updateDates, {
    onSuccess: () => {
      queryClient.invalidateQueries(['absence']);
    },
  });

  const createReason = useMutation(createSickLeaveReason, {
    onSuccess: () => {
      queryClient.invalidateQueries(['sickleaveReasons']);
    },
  });

  const handleReasonChange = (value) => {
    if (value && value.inputValue) {
      setReason(value.inputValue);
    } else {
      setReason(value);
    }
  };

  const handleSave = async () => {
    let customReason;
    if (reason && typeof reason === 'string') {
      await createReason.mutateAsync({
        name: reason,
      });
      customReason = reason;
    } else {
      customReason = reason.name || '';
    }

    const data = {
      uuid: id,
      start_date: start,
      end_date: end,
      reason: customReason,
    };

    if (user?.id) {
      data.user_id = user.id;
    }

    mutation.mutate(data);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth={'xs'} open={open} onClose={onClose} {...other}>
      <DialogContent>
        <Stack spacing={2}>
          {users && (
            <Autocomplete
              id="sickleave-employees"
              autoHighlight
              value={user}
              options={users?.sort(
                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
              )}
              onChange={(event, newValue) => {
                setUser(newValue);
              }}
              required
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.fullName}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.fullName}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Employee" placeholder="mployee" />
              )}
            />
          )}

          <ReasonAutocomplete
            onChange={handleReasonChange}
            currentValue={sickReason}
          />

          <DatePicker
            label="Start date"
            value={start}
            onChange={(newValue) => {
              setStart(newValue);
            }}
            inputFormat="dd.MM.yyyy"
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
          <DatePicker
            label="End date"
            inputFormat="dd.MM.yyyy"
            value={end}
            onChange={(newValue) => {
              setEnd(newValue);
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SickleaveEditDialog;
