import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { getWarehouse } from "../../../actions/warehouseActions";
import { addShift, startAssignedShift } from "../../../actions/shiftActions";
import { useTranslation } from "react-i18next";
import { getWarehouseNameByID } from "../../HelperFunctions/Others";
import { Divider } from "@mui/material";

const LoginToWorkPlace = ({ assigned }) => {
  const { t } = useTranslation();
  const [warehouse, setWarehouse] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWarehouse());
  }, [dispatch]);

  const user = useSelector((state) => state.user);
  const warehouses = useSelector((state) => state.warehouses);

  const startShift = (event) => {
    event.preventDefault();
    dispatch(addShift(warehouse, user.id)).catch((err) => {
      console.error("exc", err);
    });
  };

  const handleStartAssignedShift = () => {
    dispatch(startAssignedShift(assigned));
  };

  return (
    <div className="mt-5">
      <h1 className="page-heading">{t("timer.loginToWork")}</h1>
      {assigned && warehouses && (
        <>
          <div className="mb-5">
            <p>
              {t("timer.assignedShift")}{" "}
              {getWarehouseNameByID(assigned.warehouse, warehouses)}
            </p>
            <p>
              {t("timer.shiftStart")}:{" "}
              {`${("0" + new Date(assigned.assigned_start).getHours()).slice(
                -2
              )}:${("0" + new Date(assigned.assigned_start).getMinutes()).slice(
                -2
              )} - ${("0" + new Date(assigned.assigned_end).getHours()).slice(
                -2
              )}:${("0" + new Date(assigned.assigned_end).getMinutes()).slice(
                -2
              )}`}
            </p>
            <button
              className="btn btn-primary"
              onClick={() => handleStartAssignedShift()}
            >
              {t("timer.loginToWork")}
            </button>
          </div>
          <Divider className="mb-5">{t("common.or")}</Divider>
        </>
      )}

      <form onSubmit={startShift}>
        <div className="form-group">
          <label htmlFor="warehouse">{t("timer.selectWarehouse")}</label>
          <select
            onChange={({ target }) => setWarehouse(target.value)}
            className="form-control"
            id="warehouse"
            required
          >
            <option value="">{t("timer.selectWarehouse")}</option>
            {warehouses &&
              warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
          </select>
        </div>
        <button className="btn btn-primary">{t("timer.loginToWork")}</button>
      </form>
    </div>
  );
};

export default LoginToWorkPlace;
