import { useState, useEffect } from 'react';
import {
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
} from '@mui/material';

import { fTimestamp } from '../../../../utils/formatTime';

import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../../../components/table';

import { useSettingsContext } from '../../../../components/settings';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import Label from '../../../../components/label';
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
import SickleaveTableRow from '../../../../sections/@dashboard/sickleave/list/SickleaveTableRow';
import SickleaveTableToolbar from '../../../../sections/@dashboard/sickleave/list/SickleaveTableToolbar';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteAbsence, getAbsences } from '../../../../api/sickleave';
import { getUsers } from '../../../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { getWarehouse } from '../../../../actions/warehouseActions';
import SickleaveAnalytic from '../../../../sections/@dashboard/sickleave/SickleaveAnalytic';
import { useTheme } from '@mui/material/styles';
import { getSickLeaveReasons } from '../../../../api/statistics';
import { eachDayOfInterval, isWeekend } from 'date-fns';
import { isHoliday } from '../../../../utils/getDates';
const TABLE_HEAD = [
  { id: 'custom_id', label: 'ID', align: 'left' },
  { id: 'user_id', label: 'EMPLOYEE', align: 'left' },
  { id: 'reason', label: 'REASON', align: 'left' },
  { id: 'start_date', label: 'START', align: 'center' },
  { id: 'end_date', label: 'END', align: 'center' },
  { id: 'days', label: 'DAYS', align: 'center' },
  { id: 'status', label: 'STATUS', align: 'center' },
  { id: '', align: 'right' },
];

const OPTIONS_ALL = [{ id: 'all', name: 'All' }];

const SickLeaveListPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const { themeStretch } = useSettingsContext();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    //setSelected,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'time_stamp', defaultOrder: 'desc' });

  const [tableData, setTableData] = useState([]);

  const [filterName, setFilterName] = useState('');

  const [filterStatus, setFilterStatus] = useState('all');

  const [filterEndDate, setFilterEndDate] = useState(null);

  const [filterWarehouse, setFilterWarehouse] = useState(OPTIONS_ALL[0]);
  const [filterReason, setFilterReason] = useState(OPTIONS_ALL[0]);

  const [filterStartDate, setFilterStartDate] = useState(null);

  const warehouses =
    OPTIONS_ALL.concat(useSelector((state) => state.warehouses)) || [];

  const reasonsDb = useQuery(['sickleaveReasons'], getSickLeaveReasons, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const reasons = OPTIONS_ALL.concat(reasonsDb.data);

  const users = useSelector((state) => state.users);

  const query = useQuery(['absence'], getAbsences);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getWarehouse());
  }, [dispatch]);

  useEffect(() => {
    const getEmpName = (id) => {
      const user = users?.find((user) => user.id === id);
      return user ? `${user.first_name} ${user.last_name}` : '';
    };

    if (query.isSuccess) {
      query.data.forEach((item) => {
        item.days = 0;
        const result = eachDayOfInterval({
          start: new Date(item.start_date),
          end: new Date(item.end_date),
        });
        result.forEach((day) => {
          if (!isWeekend(day)) {
            if (!isHoliday(day)) {
              item.days++;
            }
          } else {
          }
        });
        item.employee = getEmpName(item.user_id);
      });

      setTableData(query.data);
    }
  }, [query, users]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterReason,
    filterName,
    filterWarehouse,
    filterStatus,
    filterStartDate,
    filterEndDate,
  });

  const dataInPage = dataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const denseHeight = dense ? 56 : 76;

  const isFiltered =
    filterStatus !== 'all' ||
    filterName !== '' ||
    filterWarehouse !== OPTIONS_ALL[0] ||
    filterReason !== OPTIONS_ALL[0] ||
    !!filterStartDate ||
    !!filterEndDate;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus) ||
    (!dataFiltered.length && !!filterWarehouse) ||
    (!dataFiltered.length && !!filterReason) ||
    (!dataFiltered.length && !!filterEndDate) ||
    (!dataFiltered.length && !!filterStartDate);

  const getLengthByStatus = (status) =>
    tableData.filter((item) => item.status === status).length;

  const getDaysByStatus = (status) => {
    let filtered;
    if (status === 'all') {
      filtered = dataFiltered;
    } else {
      filtered = dataFiltered.filter((item) => item.status === status);
    }
    let days = 0;
    filtered.forEach((item) => {
      days += item.days;
    });
    return days;
  };

  const getPercentByStatus = (status) =>
    (getDaysByStatus(status) / getDaysByStatus('all')) * 100;

  const TABS = [
    { value: 'all', label: 'All', color: 'info', count: tableData.length },
    {
      value: 'pending',
      label: 'Pending',
      color: 'warning',
      count: getLengthByStatus('pending'),
    },
    {
      value: 'accepted',
      label: 'Accepted',
      color: 'success',
      count: getLengthByStatus('accepted'),
    },
    {
      value: 'rejected',
      label: 'Rejected',
      color: 'error',
      count: getLengthByStatus('rejected'),
    },
  ];

  const handleFilterStatus = (event, newValue) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleFilterName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterWarehouse = (event) => {
    setPage(0);
    setFilterWarehouse(event.target.value);
  };

  const handleFilterReason = (value) => {
    setPage(0);
    setFilterReason(value);
  };

  const mutateDelete = useMutation(deleteAbsence, {
    onSuccess: () => {
      queryClient.invalidateQueries(['absence']);
    },
  });

  const handleDeleteRow = (id) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    mutateDelete.mutate(id);
    setTableData(deleteRow);

    if (page > 0) {
      if (dataInPage.length < 2) {
        setPage(page - 1);
      }
    }
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterStatus('all');
    setFilterWarehouse(OPTIONS_ALL[0]);
    setFilterReason(OPTIONS_ALL[0]);
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  return (
    <Container maxWidth={themeStretch ? false : 'lg'} className="p-0">
      <CustomBreadcrumbs
        heading="Sick Leave List"
        links={[
          { name: 'Dashboard', href: '/' },
          { name: 'Tools', href: '/tools' },
          { name: 'Sick Leave List' },
        ]}
      />

      <Card sx={{ mb: 5 }}>
        <Scrollbar>
          <Stack
            direction="row"
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderStyle: 'dashed' }}
              />
            }
            sx={{ py: 2 }}
          >
            <SickleaveAnalytic
              title="Total"
              total={getDaysByStatus('all')}
              percent={100}
              icon="ic:round-receipt"
              color={theme.palette.info.main}
            />
            <SickleaveAnalytic
              title="Accepted"
              total={getDaysByStatus('accepted')}
              percent={getPercentByStatus('accepted')}
              icon="eva:checkmark-circle-2-fill"
              color={theme.palette.success.main}
            />
            <SickleaveAnalytic
              title="Rejected"
              total={getDaysByStatus('rejected')}
              percent={getPercentByStatus('rejected')}
              icon="eva:close-circle-fill"
              color={theme.palette.error.main}
            />
          </Stack>
        </Scrollbar>
      </Card>

      <Card sx={{ mb: 5 }}>
        <Tabs
          value={filterStatus}
          onChange={handleFilterStatus}
          variant="scrollable"
          aria-label="scrollable tabs"
          sx={{
            px: 2,
            bgcolor: 'background.neutral',
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              icon={
                <Label color={tab.color} sx={{ mr: 1 }}>
                  {tab.count}
                </Label>
              }
            />
          ))}
        </Tabs>
        <Divider />

        <SickleaveTableToolbar
          filterName={filterName}
          isFiltered={isFiltered}
          filterWarehouse={filterWarehouse}
          filterReason={filterReason}
          filterEndDate={filterEndDate}
          onFilterName={handleFilterName}
          optionsWarehouse={warehouses}
          optionsReasons={reasons}
          filterStartDate={filterStartDate}
          onResetFilter={handleResetFilter}
          onFilterWarehouse={handleFilterWarehouse}
          onFilterReason={handleFilterReason}
          onFilterStartDate={(newValue) => {
            setFilterStartDate(newValue);
          }}
          onFilterEndDate={(newValue) => {
            setFilterEndDate(newValue);
          }}
        />

        <Divider />

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={dense}
            numSelected={selected.length}
            rowCount={tableData.length}
            onSelectAllRows={(checked) =>
              onSelectAllRows(
                checked,
                tableData.map((row) => row.id)
              )
            }
            action={
              <Stack direction="row">
                <Tooltip title="Sent">
                  <IconButton color="primary">
                    <Iconify icon="ic:round-send" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Download">
                  <IconButton color="primary">
                    <Iconify icon="eva:download-outline" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Print">
                  <IconButton color="primary">
                    <Iconify icon="eva:printer-fill" />
                  </IconButton>
                </Tooltip>
              </Stack>
            }
          />

          <Scrollbar>
            <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSort={onSort}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
              />

              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <SickleaveTableRow
                      key={row.id}
                      row={row}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                    />
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                />

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={dataFiltered.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          //
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </Container>
  );
};

export default SickLeaveListPage;
// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterWarehouse,
  filterReason,
  filterStartDate,
  filterEndDate,
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (data) =>
        data.employee.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        data.reason.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    inputData = inputData.filter((data) => data.status === filterStatus);
  }

  if (filterWarehouse !== 'all') {
    // Todo: filter by warehouse
  }

  if (filterReason && filterReason.id !== 'all') {
    inputData = inputData.filter((data) => data.reason === filterReason.name);
  }

  if (filterStartDate) {
    inputData = inputData.filter(
      (data) => fTimestamp(data.start_date) >= fTimestamp(filterStartDate)
    );
  }

  if (filterEndDate) {
    inputData = inputData.filter(
      (data) => fTimestamp(data.end_date) <= fTimestamp(filterEndDate)
    );
  }

  return inputData;
}
