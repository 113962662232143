import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  TableRow,
  Checkbox,
  TableCell,
  Typography,
  MenuItem,
} from '@mui/material';

import Iconify from '../../../components/iconify';
import MenuPopover from '../../../components/menu-popover';

//

// ----------------------------------------------------------------------

ProductTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function ProductTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}) {
  const { product, locations, amount } = row;

  const [openPopover, setOpenPopover] = useState(null);

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell>
        <Typography variant="subtitle2" noWrap>
          {product.name}
        </Typography>
      </TableCell>

      <TableCell>{product.ean}</TableCell>

      <TableCell align="center">
        <ul>{locations?.name}</ul>
      </TableCell>

      <TableCell align="right">{amount}</TableCell>

      <TableCell align="right">
        <MenuPopover
          open={openPopover}
          onClose={handleClosePopover}
          arrow="right-top"
          sx={{ width: 160 }}
        >
          <MenuItem
            onClick={() => {
              onDeleteRow();
              handleClosePopover();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon={'eva:trash-2-outline'} />
            Delete
          </MenuItem>

          <MenuItem
            onClick={() => {
              onEditRow();
              handleClosePopover();
            }}
          >
            <Iconify icon={'eva:edit-fill'} />
            Edit
          </MenuItem>
        </MenuPopover>
      </TableCell>
    </TableRow>
  );
}
