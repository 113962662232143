import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import StyledCalendarCell from "../../../components/HelperFunctions/mui/StyledCalendarCell";

import { eachDayOfInterval, startOfWeek, nextMonday, format } from "date-fns";

const findDateData = (data, date) => {
  return data.find((object) => {
    return object.date === date;
  });
};

const CreateShiftCalendar = ({ data }) => {
  const thisMonday = startOfWeek(new Date(), { weekStartsOn: 1 });
  const nexMonday = nextMonday(new Date());

  let result = eachDayOfInterval({
    start: thisMonday,
    end: nexMonday,
  });

  const dates = result.map((date) => {
    return format(date, "dd.MM");
  });

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledCalendarCell align="center"> </StyledCalendarCell>
            <StyledCalendarCell align="center">MA</StyledCalendarCell>
            <StyledCalendarCell align="center">TI</StyledCalendarCell>
            <StyledCalendarCell align="center">KE</StyledCalendarCell>
            <StyledCalendarCell align="center">TO</StyledCalendarCell>
            <StyledCalendarCell align="center">PE</StyledCalendarCell>
            <StyledCalendarCell align="center">LA</StyledCalendarCell>
            <StyledCalendarCell align="center">SU</StyledCalendarCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            key="name"
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <StyledCalendarCell component="th" scope="row">
              Date
            </StyledCalendarCell>
            <StyledCalendarCell align="center">{dates[0]}</StyledCalendarCell>
            <StyledCalendarCell align="center">{dates[1]}</StyledCalendarCell>
            <StyledCalendarCell align="center">{dates[2]}</StyledCalendarCell>
            <StyledCalendarCell align="center">{dates[3]}</StyledCalendarCell>
            <StyledCalendarCell align="center">{dates[4]}</StyledCalendarCell>
            <StyledCalendarCell align="center">{dates[5]}</StyledCalendarCell>
            <StyledCalendarCell align="center">{dates[6]}</StyledCalendarCell>
          </TableRow>
          <TableRow>
            <StyledCalendarCell component="th" scope="row">
              Warehouse
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[0])?.warehouse?.name}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[1])?.warehouse?.name}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[2])?.warehouse?.name}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[3])?.warehouse?.name}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[4])?.warehouse?.name}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[5])?.warehouse?.name}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[6])?.warehouse?.name}
            </StyledCalendarCell>
          </TableRow>
          <TableRow>
            <StyledCalendarCell component="th" scope="row">
              Principal
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[0])?.principal?.name}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[1])?.principal?.name}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[2])?.principal?.name}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[3])?.principal?.name}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[4])?.principal?.name}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[5])?.principal?.name}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[6])?.principal?.name}
            </StyledCalendarCell>
          </TableRow>
          <TableRow>
            <StyledCalendarCell component="th" scope="row">
              Start
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[0])?.start}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[1])?.start}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[2])?.start}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[3])?.start}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[4])?.start}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[5])?.start}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[6])?.start}
            </StyledCalendarCell>
          </TableRow>
          <TableRow>
            <StyledCalendarCell component="th" scope="row">
              End
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[0])?.end}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[1])?.end}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[2])?.end}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[3])?.end}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[4])?.end}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[5])?.end}
            </StyledCalendarCell>
            <StyledCalendarCell align="center">
              {findDateData(data, dates[6])?.end}
            </StyledCalendarCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CreateShiftCalendar;
