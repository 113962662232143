import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import stableSort from "../../HelperFunctions/mui/stableSort";
import getComparator from "../../HelperFunctions/mui/getComparator";
import EmptyRows from "../../HelperFunctions/mui/EmptyRows";

export default function EnhancedTableBody(props) {
  const { rows, order, orderBy, page, rowsPerPage, bodyCells } = props;

  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          return (
            <TableRow hover tabIndex={-1} key={row[bodyCells.key]}>
              {bodyCells.names.map((name) => (
                <TableCell key={name}>{row[name]}</TableCell>
              ))}
            </TableRow>
          );
        })}
      <EmptyRows
        page={page}
        rowsPerPage={rowsPerPage}
        rowsLength={rows.length}
        cells={bodyCells.names.length}
      />
    </TableBody>
  );
}
