import axios from 'axios';
import Cookies from 'universal-cookie';

const { REACT_APP_BACKEND_URL } = process.env;
const baseUrl = `${REACT_APP_BACKEND_URL}/warehouse`;

const cookies = new Cookies();

const getWarehouse = async () => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };
  const response = await axios.get(`${baseUrl}/`, config);
  const resData = response.data;
  const filteredData = resData.filter((data) => data.is_active === true);
  return filteredData;
};

const getWarehousePrincipals = async (id) => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };
  const response = await axios.get(`${baseUrl}/${id}/principal/`, config);
  const resData = response.data;
  const filteredData = resData.filter((data) => data.is_active === true);
  return filteredData;
};

const getPrincipalsTasks = async (warehouseID, principalID) => {
  const token = `Token ${cookies.get('rsid')}`;
  const config = {
    headers: { Authorization: token },
  };
  const response = await axios.get(
    `${baseUrl}/${warehouseID}/principal/${principalID}/task/`,
    config
  );
  return response.data;
};

// eslint-disable-next-line
export default { getWarehouse, getWarehousePrincipals, getPrincipalsTasks };
