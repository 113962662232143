import warehouseApi from '../api/warehouse'

export const getWarehouse = () => {
    return async dispatch => {
        const warehouse = await warehouseApi.getWarehouse()
        dispatch({
            type: 'GETORG',
            payload: warehouse
        })
    }
}

export const getWarehousePrincipals = (id) => {
    return async dispatch => {
        const principals = await warehouseApi.getWarehousePrincipals(id)
        dispatch({
            type: 'GETPRINCIPAL',
            payload: principals
        })
    }
}

export const getPrincipalsTasks = (warehouseID, principalID) => {
    return async dispatch => {
        const tasks = await warehouseApi.getPrincipalsTasks(warehouseID, principalID)
        dispatch({
            type: 'GETPRINCIPALTASKS',
            payload: tasks
        })
    }
}
