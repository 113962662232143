import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { latestSevenDays } from '../../../actions/reportAction';
import { useDispatch, useSelector } from 'react-redux';
import { getWarehouseNameByID } from '../../HelperFunctions/convert';
import {
  calcDifferenceTime,
  convertIsoToLocalDate,
  extractTime,
} from '../../../utils/DateTime';
import { useWindowWidth } from '@react-hook/window-size';
import { compareAsc, compareDesc } from 'date-fns';

function createData(id, worker, warehouse, checkin, checkout, total) {
  return {
    id,
    worker,
    warehouse,
    checkin,
    checkout,
    total,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function compareDateTimeAsc(a, b) {
  // a = '15.11.2021 23.00.00'
  // b = '15.11.2021 20.00.19'

  // split a and b by ' '
  const aSplit = a.split(' ');
  const bSplit = b.split(' ');

  // split a and b by '.'
  const aDate = aSplit[0].split('.');
  const bDate = bSplit[0].split('.');

  // split a and b by '.'
  const aTime = aSplit[1].split('.');
  const bTime = bSplit[1].split('.');

  // create dates from a and b
  const aDateTime = new Date(
    parseInt(aDate[2], 10),
    parseInt(aDate[1], 10),
    parseInt(aDate[0], 10),
    parseInt(aTime[0], 10),
    parseInt(aTime[1], 10),
    parseInt(aTime[2], 10)
  );
  const bDateTime = new Date(
    parseInt(bDate[2], 10),
    parseInt(bDate[1], 10),
    parseInt(bDate[0], 10),
    parseInt(bTime[0], 10),
    parseInt(bTime[1], 10),
    parseInt(bTime[2], 10)
  );

  // compare dates
  return compareAsc(aDateTime, bDateTime);
}

function compareDateTimeDesc(a, b) {
  // a = '15.11.2021 23.00.00'
  // b = '15.11.2021 20.00.19'

  // split a and b by ' '
  const aSplit = a.split(' ');
  const bSplit = b.split(' ');

  // split a and b by '.'
  const aDate = aSplit[0].split('.');
  const bDate = bSplit[0].split('.');

  // split a and b by '.'
  const aTime = aSplit[1].split('.');
  const bTime = bSplit[1].split('.');

  // create dates from a and b
  const aDateTime = new Date(
    parseInt(aDate[2], 10),
    parseInt(aDate[1], 10),
    parseInt(aDate[0], 10),
    parseInt(aTime[0], 10),
    parseInt(aTime[1], 10),
    parseInt(aTime[2], 10)
  );
  const bDateTime = new Date(
    parseInt(bDate[2], 10),
    parseInt(bDate[1], 10),
    parseInt(bDate[0], 10),
    parseInt(bTime[0], 10),
    parseInt(bTime[1], 10),
    parseInt(bTime[2], 10)
  );

  // compare dates
  return compareDesc(aDateTime, bDateTime);
}

function getComparator(order, orderBy) {
  if (orderBy === 'checkin' || orderBy === 'checkout') {
    if (order === 'desc') {
      return (a, b) => {
        return compareDateTimeDesc(a[orderBy], b[orderBy]);
      };
    } else {
      return (a, b) => {
        return compareDateTimeAsc(a[orderBy], b[orderBy]);
      };
    }
  }

  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'worker',
    numeric: false,
    label: 'Worker',
  },
  {
    id: 'warehouse',
    numeric: true,
    label: 'Warehouse',
  },
  {
    id: 'checkin',
    numeric: true,
    label: 'Check in Time',
  },
  {
    id: 'checkout',
    numeric: true,
    label: 'Check out time',
  },
  {
    id: 'total',
    numeric: true,
    label: 'Total time',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function CompletedShifts() {
  const dispatch = useDispatch();

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('checkout');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [mobileTable, setMobileTable] = React.useState(false);

  const scrWidth = useWindowWidth();

  React.useEffect(() => {
    dispatch(latestSevenDays());
  }, [dispatch]);

  React.useEffect(() => {
    if (scrWidth < 992 && !mobileTable) {
      setMobileTable(true);
    }
    if (scrWidth >= 992 && mobileTable) {
      setMobileTable(false);
    }
  }, [scrWidth, mobileTable]);

  let reports = useSelector((state) => state.report.LatestWeek);
  let warehouses = useSelector((state) => state.warehouses);
  const rows = [];
  reports?.map((report) => {
    return rows.push(
      createData(
        report.id,
        report.worker_name,
        getWarehouseNameByID(report.warehouse, warehouses),
        convertIsoToLocalDate(report.check_in) +
          ' ' +
          extractTime(report.check_in),
        convertIsoToLocalDate(report.check_out) +
          ' ' +
          extractTime(report.check_out),
        calcDifferenceTime(report.check_in, report.check_out)
      )
    );
  });
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table aria-labelledby="tableTitle">
            {!mobileTable && (
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
            )}
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <>
                      {mobileTable ? (
                        <>
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row.id}
                            sx={{ bgcolor: '#cccccc' }}
                          >
                            <TableCell>
                              <b>Worker</b>
                            </TableCell>

                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              align="right"
                            >
                              <b>{row.worker}</b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Warehouse</TableCell>
                            <TableCell align="right">{row.warehouse}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Check in Time</TableCell>
                            <TableCell align="right">{row.checkin}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Check out time</TableCell>
                            <TableCell align="right">{row.checkout}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Total time</TableCell>
                            <TableCell align="right">{row.total}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell />
                          </TableRow>
                        </>
                      ) : (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          <TableCell component="th" id={labelId} scope="row">
                            {row.worker}
                          </TableCell>
                          <TableCell align="right">{row.warehouse}</TableCell>
                          <TableCell align="right">{row.checkin}</TableCell>
                          <TableCell align="right">{row.checkout}</TableCell>
                          <TableCell align="right">{row.total}</TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
