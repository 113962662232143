import { Typography } from '@mui/material';
import { MdHealing } from 'react-icons/md';

export default function renderContent(notification, t, i18n) {
  const accepted = notification?.description.search('Accepted');
  const rejected = notification?.description.search('Rejected');
  const description = notification?.description.split(',');

  const getDesctiption = () => {
    let desc = notification.description;

    if (i18n.exists(`notification.${notification.description}`)) {
      return t(`notification.${notification.description}`);
    }
    if (notification.type === 'sickleave') {
      if (accepted !== -1) {
        return t('notification.accepted');
      }
      if (rejected !== -1) {
        desc = t('notification.rejected');
        desc = `${desc},${description.slice(1).join(',')}`;
      }
    }

    return desc;
  };

  const title = (
    <Typography variant="subtitle2">
      {i18n.exists(`notification.${notification.title}`)
        ? t(`notification.${notification.title}`)
        : notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: 'text.secondary' }}
      >
        &nbsp; {getDesctiption()}
      </Typography>
    </Typography>
  );

  if (notification.type === 'sickleave') {
    return {
      avatar: <MdHealing />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/notification/ic_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/notification/ic_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? (
      <img alt={notification.title} src={notification.avatar} />
    ) : null,
    title,
  };
}
