import React, { useState } from "react";

import RenderReportSection from "./RenderReportSection";
import SearchSection from "./SearchSection";

import { useTranslation } from "react-i18next";

const EmSearch = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="mt-3">
        <h4 className="page-heading">{t("report.report")}</h4>
      </div>
      <SearchSection setLoading={setLoading} />
      <RenderReportSection loading={loading} setLoading={setLoading} />
    </>
  );
};

export default EmSearch;
