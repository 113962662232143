import { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  Tooltip,
  Divider,
  TableBody,
  IconButton,
  TableContainer,
} from '@mui/material';

import { fTimestamp } from '../../../utils/formatTime';

import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../../components/table';

import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { useQuery } from '@tanstack/react-query';
import { getUserAbsences } from '../../../api/sickleave';
import { useDispatch, useSelector } from 'react-redux';
import { getWarehouse } from '../../../actions/warehouseActions';
import SickleaveAnalytic from './SickleaveAnalytic';
import { useTheme } from '@mui/material/styles';
import { eachDayOfInterval, isWeekend } from 'date-fns';
import { isHoliday } from '../../../utils/getDates';
import ReportTableRow from './list/ReportTableRow';
const TABLE_HEAD = [
  { id: 'start_date', label: 'START', align: 'center' },
  { id: 'end_date', label: 'END', align: 'center' },
  { id: 'days', label: 'DAYS', align: 'center' },
  { id: 'status', label: 'STATUS', align: 'center' },
];

const OPTIONS_ALL = [{ id: 'all', name: 'All' }];

const SickLeaveListPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    //setSelected,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'time_stamp', defaultOrder: 'desc' });

  const user = useSelector((state) => state.user);

  const [tableData, setTableData] = useState([]);

  const filterName = '';

  const filterStatus = 'all';

  const { filterStartDate, filterEndDate } = '';

  const { filterWarehouse, filterReason } = OPTIONS_ALL[0];

  const query = useQuery(['absence'], () => getUserAbsences(user.id));

  useEffect(() => {
    dispatch(getWarehouse());
  }, [dispatch]);

  useEffect(() => {
    if (query.isSuccess) {
      query.data.forEach((item) => {
        item.days = 0;
        const result = eachDayOfInterval({
          start: new Date(item.start_date),
          end: new Date(item.end_date),
        });
        result.forEach((day) => {
          if (!isWeekend(day)) {
            if (!isHoliday(day)) {
              item.days++;
            }
          } else {
          }
        });
      });

      setTableData(query.data);
    }
  }, [query]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterReason,
    filterName,
    filterWarehouse,
    filterStatus,
    filterStartDate,
    filterEndDate,
  });

  const denseHeight = dense ? 56 : 76;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus) ||
    (!dataFiltered.length && !!filterWarehouse) ||
    (!dataFiltered.length && !!filterReason) ||
    (!dataFiltered.length && !!filterEndDate) ||
    (!dataFiltered.length && !!filterStartDate);

  const getDaysByStatus = (status) => {
    let filtered;
    if (status === 'all') {
      filtered = dataFiltered;
    } else {
      filtered = dataFiltered.filter((item) => item.status === status);
    }
    let days = 0;
    filtered.forEach((item) => {
      days += item.days;
    });
    return days;
  };

  const getPercentByStatus = (status) =>
    (getDaysByStatus(status) / getDaysByStatus('all')) * 100;

  return (
    <>
      <Card sx={{ mb: 5 }}>
        <Scrollbar>
          <Stack
            direction="row"
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderStyle: 'dashed' }}
              />
            }
            sx={{ py: 2 }}
          >
            <SickleaveAnalytic
              title="Total"
              total={getDaysByStatus('all')}
              percent={100}
              icon="ic:round-receipt"
              color={theme.palette.info.main}
            />
            <SickleaveAnalytic
              title="Accepted"
              total={getDaysByStatus('accepted')}
              percent={getPercentByStatus('accepted')}
              icon="eva:checkmark-circle-2-fill"
              color={theme.palette.success.main}
            />
            <SickleaveAnalytic
              title="Rejected"
              total={getDaysByStatus('rejected')}
              percent={getPercentByStatus('rejected')}
              icon="eva:close-circle-fill"
              color={theme.palette.error.main}
            />
          </Stack>
        </Scrollbar>
      </Card>

      <Card>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={dense}
            numSelected={selected.length}
            rowCount={tableData.length}
            onSelectAllRows={(checked) =>
              onSelectAllRows(
                checked,
                tableData.map((row) => row.id)
              )
            }
            action={
              <Stack direction="row">
                <Tooltip title="Sent">
                  <IconButton color="primary">
                    <Iconify icon="ic:round-send" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Download">
                  <IconButton color="primary">
                    <Iconify icon="eva:download-outline" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Print">
                  <IconButton color="primary">
                    <Iconify icon="eva:printer-fill" />
                  </IconButton>
                </Tooltip>
              </Stack>
            }
          />

          <Scrollbar>
            <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSort={onSort}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
              />

              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <ReportTableRow key={row.id} row={row} />
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                />

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={dataFiltered.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          //
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
};

export default SickLeaveListPage;
// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterWarehouse,
  filterReason,
  filterStartDate,
  filterEndDate,
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (data) =>
        data.employee.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        data.reason.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    inputData = inputData.filter((data) => data.status === filterStatus);
  }

  if (filterWarehouse !== 'all') {
    // Todo: filter by warehouse
  }

  if (filterReason && filterReason.id !== 'all') {
    inputData = inputData.filter((data) => data.reason === filterReason.name);
  }

  if (filterStartDate) {
    inputData = inputData.filter(
      (data) => fTimestamp(data.start_date) >= fTimestamp(filterStartDate)
    );
  }

  if (filterEndDate) {
    inputData = inputData.filter(
      (data) => fTimestamp(data.end_date) <= fTimestamp(filterEndDate)
    );
  }

  return inputData;
}
