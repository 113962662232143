const shiftReducer = (state = null, action) => {
  switch (action.type) {
    case "LOGOUT":
      return null;
    case "GETSHIFT":
      return action.payload;
    case "STARTWORK":
      return action.payload;
    case "STOPWORK":
      return action.payload;
    case "SWITCHWORK":
      return action.payload;

    default:
      return state;
  }
};

export default shiftReducer;
