import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import PropTypes from "prop-types";

export default function EmptyRows(props) {
  const { page, rowsPerPage, rowsLength, cells } = props;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsLength) : 0;

  return (
    <>
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: 53 * emptyRows,
          }}
        >
          <TableCell colSpan={cells} />
        </TableRow>
      )}
    </>
  );
}

EmptyRows.propTypes = {
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsLength: PropTypes.number.isRequired,
  cells: PropTypes.number.isRequired,
};
