import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";

const ChangeLanguageBar = ({ userEmail }) => {
  const { i18n } = useTranslation();

  return (
    <div className="container">
      <div
        className={`change-language-bar d-flex my-2 ${
          userEmail ? "justify-content-between" : "justify-content-end"
        }`}
      >
        {userEmail && (
          <div>
            <span>ID: {userEmail}</span>
          </div>
        )}
        <div>
          <ReactCountryFlag
            svg
            className="emojiFlag cursor-pointer mx-2"
            countryCode="GB"
            style={{
              fontSize: "2em",
              lineHeight: "2em",
            }}
            aria-label="English"
            onClick={() => i18n.changeLanguage("en")}
          />
          <ReactCountryFlag
            svg
            className="emojiFlag cursor-pointer mx-2"
            countryCode="FI"
            style={{
              fontSize: "2em",
              lineHeight: "2em",
            }}
            aria-label="Finnish"
            onClick={() => i18n.changeLanguage("fi")}
          />
          <ReactCountryFlag
            svg
            className="emojiFlag cursor-pointer ml-2"
            countryCode="VN"
            style={{
              fontSize: "2em",
              lineHeight: "2em",
            }}
            aria-label="Vietnamese"
            onClick={() => i18n.changeLanguage("vi")}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangeLanguageBar;
