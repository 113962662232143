// @mui
import { Container} from '@mui/material';

import useSettings from '../../../hooks/useSettings';

import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { BsBookshelf } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import forklift from '../../../assets/images/forklift.png';
import { useTranslation } from 'react-i18next';

const SafetyPage = () => {
  const { themeStretch } = useSettings();
  const { t } = useTranslation();

  return (
    <Page title="Setting: Safety">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={t('navigation.safety')}
          links={[
            { name: 'Dashboard', href: '/' },
            { name: t('navigation.safety') },
          ]}
        />
        <IconContext.Provider value={{ color: '#de0e19', size: '100' }}>
        
          <div className='row'>
            <div className="col-6 col-lg-4 mt-2">
                <div className="pt-4 text-center">
                  <BsBookshelf />
                  <div className="card-body">
                    <h5 className="card-title">{t('navigation.rackSafety')}</h5>
                  </div>
                </div>
            </div>

            <div className="col-6 col-lg-4 mt-2">
              <div className="pt-2 text-center">
                  <img
                    src={forklift}
                    alt="avg kerroin logo"
                    height="120px"
                  />
                <div className="card-body">
                  <h5 className="card-title">{t('navigation.machineSafety')}</h5>
                </div>
              </div>
            </div>
          </div>
        </IconContext.Provider>
      </Container>
    </Page>
  );
};

export default SafetyPage;
