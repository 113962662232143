export const convertIsoToLocalDate = (iso) => {
  const date = new Date(iso);
  return date.toLocaleDateString('fi');
};

export const extractTime = (dateTimeStr) => {
  // Parse the input string into a Date object
  const date = new Date(dateTimeStr);
  // Extract the hours, minutes, and seconds from the Date object
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  // Add leading zeros to the time components if necessary
  hours = hours.toString().padStart(2, '0');
  minutes = minutes.toString().padStart(2, '0');
  seconds = seconds.toString().padStart(2, '0');
  // Format the time component as a string
  const time = `${hours}:${minutes}:${seconds}`;
  return time;
};

export const getTime = (timestamp) => {
  timestamp = timestamp - 2 * 60 * 60 * 1000;
  const dateObject = new Date(timestamp); // Thu Jan 01 1970 04:01:03 GMT+0200 (Eastern European Standard Time)
  let hours = dateObject.getHours(); // 4
  let minutes = dateObject.getMinutes(); // 1
  if (hours >= 7 && minutes >= 30) {
    minutes = minutes - 30;
  } else if (hours >= 7) {
    let timeRemain = minutes - 30;
    hours = hours - 1;
    minutes = 60 + timeRemain;
  }

  const result = `${('0' + hours).substr(-2)}:${('0' + minutes).substr(-2)}`;
  return result;
};

export const getTimeDecimal = (timestamp) => {
  timestamp = timestamp - 2 * 60 * 60 * 1000;
  const dateObject = new Date(timestamp); // Thu Jan 01 1970 04:01:03 GMT+0200 (Eastern European Standard Time)
  let hours = dateObject.getHours(); // 4
  let minutes = dateObject.getMinutes(); // 1
  if (hours >= 7 && minutes >= 30) {
    minutes = minutes - 30;
  } else if (hours >= 7) {
    let timeRemain = minutes - 30;
    hours = hours - 1;
    minutes = 60 + timeRemain;
  }
  let decimal = minutes / 60;
  decimal = decimal.toFixed(2);

  const result = `${hours},${decimal.substr(-2)}`;
  return result;
};

export const calcDifferenceTime = (startTime, endTime) => {
  // startTime = 2021-10-23T02:18:32.174000+03:00
  // endTime = 2021-10-23T04:19:35.189000+03:00
  const differenceTimeStamp = Date.parse(endTime) - Date.parse(startTime); // 7263015

  if (!differenceTimeStamp) {
    return null;
  }

  return getTime(differenceTimeStamp);
};

export const calcDifferenceTimeDecimal = (startTime, endTime) => {
  // startTime = 2021-10-23T02:18:32.174000+03:00
  // endTime = 2021-10-23T04:19:35.189000+03:00
  const differenceTimeStamp = Date.parse(endTime) - Date.parse(startTime); // 7263015

  if (!differenceTimeStamp) {
    return null;
  }

  return getTimeDecimal(differenceTimeStamp);
};

export const countTotalWorkTime = (total) => {
  let sumTimeStamp = 0;
  total.forEach((element) => {
    const diff = new Date(element.end) - new Date(element.start);
    if (diff > 0) {
      sumTimeStamp = sumTimeStamp + diff;
    }
  });
  return getTime(sumTimeStamp);
};

export const dateToUtcStart = (date) => {
  const dateq = new Date(date);
  const localDateTime = new Date();
  const getTimezoneOffset = localDateTime.getTimezoneOffset();
  const GMThour = Math.abs(getTimezoneOffset) / 60;
  const GMT = `GMT+0${GMThour}00`;
  const dateTime = new Date(`${dateq.toDateString()} 00:00:00 ${GMT}`);
  return dateTime.toISOString();
};

export const dateToUtcEnd = (date) => {
  const dateq = new Date(date);
  const localDateTime = new Date();
  const getTimezoneOffset = localDateTime.getTimezoneOffset();
  const GMThour = Math.abs(getTimezoneOffset) / 60;
  const GMT = `GMT+0${GMThour}00`;
  const dateTime = new Date(`${dateq.toDateString()} 23:59:59 ${GMT}`);
  return dateTime.toISOString();
};

export const timestampToDecimal = (timestamp) => {
  const result = timestamp / (60 * 60);
  return result.toFixed(2).replace('.', ',');
};

export const getDateByString = (dateString) => {
  if (!dateString) {
    return null;
  }
  const split = dateString.split('.');
  const date = new Date(split[2], split[1] - 1, split[0]);
  return date;
};

export const formatSeconds = (totalSeconds)  => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  let decimal = minutes / 60;
  decimal = decimal.toFixed(2);

  const result = `${hours},${decimal.substr(-2)}`;
  return result;
}
