import { useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "../../actions/notificationActions";
import { createUser } from "../../actions/userActions";
import { useNavigate } from "react-router-dom";

const Registration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sotu, setSotu] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    if (password !== cpassword) {
      dispatch(setMessage("The two password fields didn't match.", "error", 5));
    } else if (password.length < 8) {
      dispatch(
        setMessage(
          "This password is too short. It must contain at least 8 characters.",
          "error",
          5
        )
      );
    } else {
      const user = {
        email: email.toLowerCase(),
        password: password,
        password_again: cpassword,
        first_name: firstName,
        last_name: lastName,
        social_number: sotu,
        phone: phoneNumber,
      };
      dispatch(createUser(user))
        .then(() => {
          dispatch(
            setMessage("welcome, you have been registered", "success", 5)
          );
          navigate("/");
        })
        .catch((err) => {
          Object.values(err.response.data).forEach((val) => {
            dispatch(setMessage(val, "error", 5));
          });
          setPassword("");
          setcPassword("");
        });
    }
  };

  return (
    <div className="p-5 rounded border col-lg-6 col-md-8 col-sm-12">
      <div className="mb-5">
        <h4>Create your account</h4>
      </div>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="first_name">First Name</label>
          <input
            className="form-control"
            type="text"
            value={firstName}
            name="first_name"
            onChange={({ target }) => setFirstName(target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="last_name">Last Name</label>
          <input
            className="form-control"
            type="text"
            value={lastName}
            name="last_name"
            onChange={({ target }) => setLastName(target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="ssn">Social Number</label>
          <input
            className="form-control"
            type="text"
            value={sotu}
            name="ssn"
            onChange={({ target }) => setSotu(target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">
            Phone Number <small>(+35845789012)</small>
          </label>
          <input
            className="form-control"
            type="tel"
            value={phoneNumber}
            name="phoneNumber"
            placeholder="+358456789012"
            onChange={({ target }) => setPhoneNumber(target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            className="form-control"
            type="email"
            value={email}
            name="email"
            onChange={({ target }) => setEmail(target.value)}
            autoCapitalize="off"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            className="form-control"
            type="password"
            value={password}
            name="password"
            onChange={({ target }) => setPassword(target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="cpassword">Confirm password</label>
          <input
            className="form-control"
            type="password"
            value={cpassword}
            name="cpassword"
            onChange={({ target }) => setcPassword(target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary form-group">
          Create new profile
        </button>
      </form>
    </div>
  );
};

export default Registration;
