import {
  addDoc,
  doc,
  getDocs,
  query,
  where,
  updateDoc,
  orderBy,
} from 'firebase/firestore';
import { formatDocs } from '../utils/formatFirestore';

import { notificationsCollectionRef } from '../db/firestore';

export const getNotifications = async (user_id) => {
  const q = query(
    notificationsCollectionRef,
    where('user_id', '==', user_id),
    orderBy('createdAt', 'desc')
  );
  const response = await getDocs(q);
  return formatDocs(response);
};

export const readedNotification = async (id) => {
  const notificationRef = doc(notificationsCollectionRef, id);
  await updateDoc(notificationRef, {
    isRead: true,
  });
};

export const markAllNotificationReaded = async (user_id) => {
  const q = query(
    notificationsCollectionRef,
    where('user_id', '==', user_id),
    where('isRead', '==', false)
  );
  const response = await getDocs(q);
  const notifications = formatDocs(response);
  notifications.forEach(async (notification) => {
    const notificationRef = doc(notificationsCollectionRef, notification.id);
    await updateDoc(notificationRef, {
      isRead: true,
    });
  });
};

export const createNotification = async (data) => {
  const response = await addDoc(notificationsCollectionRef, data);
  return response;
};
