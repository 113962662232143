import { Dialog, DialogContent } from '@mui/material';

const ImageDialog = ({ open, onClose, image, ...other }) => {
  return (
    <Dialog maxWidth={'md'} open={open} onClose={onClose} {...other}>
      <DialogContent>
        <img src={image} className="dialog-image" alt="random" />
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
