import { Link } from 'react-router-dom';
import searchlogo from '../../../assets/images/search.svg';
import piechart from '../../../assets/images/Pie_search_3.svg';
import listingworker from '../../../assets/images/listingworker.svg';
import { useTranslation } from 'react-i18next';

const ManagerReport = () => {
  const { t } = useTranslation();
  return (
    <div className="my-2 row">
      <div className="col-6 col-lg-4 mt-2">
        <Link to="/tools/reports/search">
          <div className="pt-2 text-center">
            <div className="icon-color">
              <img
                src={searchlogo}
                alt="search logo"
                width="120px"
                height="120px"
              />
            </div>
            <div className="card-body">
              <h5 className="card-title">{t('report.report')}</h5>
            </div>
          </div>
        </Link>
      </div>

      <div className="col-6 col-lg-4 mt-2">
        <Link to="/tools/reports/statistics">
          <div className="pt-2 text-center">
            <div className="icon-color">
              <img
                src={piechart}
                alt="pie-chart logo"
                width="120px"
                height="120px"
              />
            </div>
            <div className="card-body">
              <h5 className="card-title">{t('navigation.marketShare')}</h5>
            </div>
          </div>
        </Link>
      </div>

      <div className="col-6 col-lg-4 mt-2">
        <Link to="/tools/reports/employees">
          <div className="pt-2 text-center">
            <div className="icon-color">
              <img
                src={listingworker}
                alt="pie-chart logo"
                width="120px"
                height="120px"
              />
            </div>
            <div className="card-body">
              <h5 className="card-title">{t('navigation.listOfEmployees')}</h5>
            </div>
          </div>
        </Link>
      </div>

      <div className="col-6 col-lg-4 mt-2">
        <Link to="/tools/reports/bill">
          <div className="pt-2 text-center">
            <div className="icon-color">
              <img
                src={listingworker}
                alt="pie-chart logo"
                width="120px"
                height="120px"
              />
            </div>
            <div className="card-body">
              <h5 className="card-title">Billing Report</h5>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ManagerReport;
