import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  getWarehousePrincipals,
  getPrincipalsTasks,
} from "../../../actions/warehouseActions";
import { postShiftTask } from "../../../actions/shiftActions";
import { confirmAlert } from "react-confirm-alert";
import { stopWork } from "../../../actions/shiftActions";
import { useNavigate } from "react-router-dom";
import { sortObject } from "../../HelperFunctions/Others";
import { useTranslation } from "react-i18next";

const TaskForm = () => {
  const [task, setTask] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const principals = sortObject(
    useSelector((state) => state.principal),
    "name"
  );

  const warehouses = sortObject(
    useSelector((state) => state.warehouses),
    "name"
  );

  const tasks = sortObject(
    useSelector((state) => state.principalTasks),
    "title"
  );

  const shift = useSelector((state) => state.shift);

  let latestShift = shift.find(
    (shift) => shift.check_out === null && shift.is_working === true
  );

  let currentWarehouseName = { name: "" };
  if (warehouses) {
    currentWarehouseName = warehouses.find(
      (warehouse) => warehouse.id === latestShift.warehouse
    );
  }
  let activeShift = false;

  if (shift && shift.length !== 0) {
    activeShift = shift.find((e) => e.is_working === true);
  }

  useEffect(() => {
    dispatch(getWarehousePrincipals(activeShift.warehouse));
  }, [dispatch, activeShift]);

  const handleChangePrincipal = (e) => {
    const id = e.target.value;
    dispatch(getPrincipalsTasks(activeShift.warehouse, id));
  };

  const startTimer = (event) => {
    event.preventDefault();
    dispatch(postShiftTask(activeShift.id, task));
  };

  const stopWorking = (event) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-alert">
            <h1>{t("timer.warning")}</h1>
            <p>{t("timer.warningText")}</p>
            <div className="warning-buttons">
              <button className="cancel btn" onClick={onClose}>
                {t("timer.cancel")}
              </button>
              <button
                className="stop btn btn-danger"
                onClick={() => {
                  dispatch(stopWork(latestShift.id));
                  navigate("/");
                  onClose();
                }}
              >
                {t("timer.stopWorking")}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const getTime = (timestamp) => {
    const dateObject = new Date(timestamp);
    const hours = ("0" + dateObject.getHours()).substr(-2);
    const minutes = ("0" + dateObject.getMinutes()).substr(-2);

    const result = `${hours}:${minutes}`;
    return result;
  };

  return (
    <div className="mt-5">
      <h2 className="page-heading">
        <b>{t("timer.startWork")}</b>
      </h2>
      <form>
        <h5 className="mt-4">
          <b>{t("timer.note")}</b> {t("timer.noteText")}{" "}
          {currentWarehouseName.name}, {t("timer.klo")}:{" "}
          {getTime(Date.parse(latestShift.check_in))}
        </h5>
        <div className="form-group mt-4">
          <label htmlFor="principal">
            <b>{t("timer.selectPrincipal")}</b>
          </label>
          <select
            onChange={handleChangePrincipal}
            className="form-control"
            id="principal"
            required
          >
            <option value="">{t("timer.selectPrincipal")}</option>
            {principals &&
              principals.map((principal) => (
                <option key={principal.id} value={principal.id}>
                  {principal.name}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="task">
            <b>{t("timer.selectWorktype")}</b>
          </label>
          <select
            onChange={({ target }) => setTask(target.value)}
            className="form-control"
            id="task"
            required
          >
            <option value="">{t("timer.selectWorktype")}</option>
            {tasks &&
              tasks.map((task) => (
                <option key={task.id} value={task.id}>
                  {t(`${task.title}`)}
                  {
                    // task.title
                  }
                </option>
              ))}
          </select>
        </div>
        <div className="d-flex justify-content-between">
          <button className="btn btn-danger" onClick={stopWorking}>
            <b>{t("timer.stopWorking")}</b>
          </button>
          <button className="btn btn-startJob" onClick={startTimer}>
            <b>{t("timer.startWorking")}</b>
          </button>
        </div>
      </form>
    </div>
  );
};

export default TaskForm;
