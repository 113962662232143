import React from "react";
import { Modal, Button } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
const rules = [
  "rule1",
  "rule2",
  "rule3",
  "rule4",
  "rule5",
  "rule6",
  "rule7",
  "rule8",
  "rule9",
  "rule10",
  "rule11",
  "rule12",
  "rule13",
  "rule14",
  "rule15",
  "rule16",
  "rule17",
  "rule18",
  "rule19",
  "rule20",
  "rule21",
  "rule22",
  "rule23",
  "rule24",
];

const Rules = ({ show, setShown }) => {
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    setShown(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="text-red">
            {t("navigation.rules")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-right">
            <ReactCountryFlag
              svg
              className="emojiFlag cursor-pointer mx-2"
              countryCode="GB"
              style={{
                fontSize: "2em",
                lineHeight: "2em",
              }}
              aria-label="English"
              onClick={() => i18n.changeLanguage("en")}
            />
            <ReactCountryFlag
              svg
              className="emojiFlag cursor-pointer mx-2"
              countryCode="FI"
              style={{
                fontSize: "2em",
                lineHeight: "2em",
              }}
              aria-label="Finnish"
              onClick={() => i18n.changeLanguage("fi")}
            />
            <ReactCountryFlag
              svg
              className="emojiFlag cursor-pointer mx-2"
              countryCode="VN"
              style={{
                fontSize: "2em",
                lineHeight: "2em",
              }}
              aria-label="Vietnamese"
              onClick={() => i18n.changeLanguage("vi")}
            />
          </div>
          <ol>
            {rules.map((rule, index) => (
              <li key={index} className="my-3">
                {t(`rules.${rule}`)}
              </li>
            ))}
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("common.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Rules;
