import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { userChagePassword } from "../../../actions/userActions";
import { useNavigate } from "react-router-dom";
import { setMessage } from "../../../actions/notificationActions";
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSave = async (event) => {
    event.preventDefault();
    if (password === confirmPassword) {
      const result = await dispatch(
        userChagePassword(password, confirmPassword)
      ).catch((err) => {
        console.error("exc", err);
      });
      if (result.status === 200) {
        navigate("/");
      }
    } else {
      dispatch(setMessage("Salasanat eivät täsmää.", "error", 5));
    }
  };

  return (
    <div>
      <div className="mb-5">
        <h4>{t("setting.changePassword")}</h4>
      </div>
      <form onSubmit={handleSave}>
        <div className="form-group">
          <label htmlFor="password">{t("setting.newPassword")}</label>
          <input
            className="form-control"
            type="password"
            value={password}
            name="password"
            onChange={({ target }) => setPassword(target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">
            {t("setting.confirmPassword")}
          </label>
          <input
            className="form-control"
            type="password"
            value={confirmPassword}
            name="confirmPassword"
            onChange={({ target }) => setConfirmPassword(target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary form-group">
          {t("setting.save")}
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
