import { useState } from "react";

export default function usePagesHandler(defaultPage, defaultRowsPerPage) {
  const [page, setPage] = useState(defaultPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  return { page, handleChangePage, rowsPerPage, handleChangeRowsPerPage };
}
