import React, { Component } from 'react';

const isTouchDevice = 'ontouchstart' in document.documentElement;

export default class ReactSwipeButton extends Component {
  isDragging = false;
  sliderLeft = 0;

  state = {};

  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.container = React.createRef();
  }

  componentDidMount() {
    if (isTouchDevice) {
      document.addEventListener('touchmove', this.onDrag);
      document.addEventListener('touchend', this.stopDrag);
    } else {
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
    }
    this.containerWidth = this.container.current.clientWidth - 50;
  }

  onDrag = (e) => {
    if (this.unmounted || this.state.unlocked) return;
    if (this.isDragging) {
      if (isTouchDevice) {
        this.sliderLeft = Math.min(
          Math.max(0, e.touches[0].clientX - this.startX),
          this.containerWidth
        );
      } else {
        this.sliderLeft = Math.min(
          Math.max(0, e.clientX - this.startX),
          this.containerWidth
        );
      }
      this.updateSliderStyle();
    }
  };

  updateSliderStyle = () => {
    if (this.unmounted || this.state.unlocked) return;
    this.slider.current.style.left = this.sliderLeft + 50 + 'px';
  };

  stopDrag = () => {
    if (this.unmounted || this.state.unlocked) return;
    if (this.isDragging) {
      this.isDragging = false;
      if (this.sliderLeft > this.containerWidth * 0.9) {
        this.sliderLeft = this.containerWidth;
        this.onSuccess();
        if (this.props.onSuccess) {
          this.props.onSuccess();
        }
      } else {
        this.sliderLeft = 0;
        if (this.props.onFailure) {
          this.props.onFailure();
        }
      }
      this.updateSliderStyle();
    }
  };

  startDrag = (e) => {
    if (this.unmounted || this.state.unlocked) return;
    this.isDragging = true;
    if (isTouchDevice) {
      this.startX = e.touches[0].clientX;
    } else {
      this.startX = e.clientX;
    }
  };

  onSuccess = () => {
    this.container.current.style.width =
      this.container.current.clientWidth + 'px';
    this.setState({
      unlocked: false,
    });
    setTimeout(() => {
      this.reset();
    }, 500);
  };

  getText = () => {
    return this.state.unlocked
      ? this.props.text_unlocked || 'UNLOCKED'
      : this.props.text || 'SLIDE';
  };

  reset = () => {
    if (this.unmounted) return;
    this.setState({ unlocked: false }, () => {
      this.sliderLeft = 0;
      this.updateSliderStyle();
    });
  };

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    return (
      <div className="ReactSwipeButton">
        <div
          className={
            'rsbContainer ' +
            (this.state.unlocked ? 'rsbContainerUnlocked' : '')
          }
          ref={this.container}
        >
          <div
            className={'rsbcSlider'}
            ref={this.slider}
            onMouseDown={this.startDrag}
            style={{ background: this.props.color }}
            onTouchStart={this.startDrag}
          >
            <span className="rsbcSliderText">
              <b>{this.getText()}</b>
            </span>
            <span className="rsbcSliderArrow"></span>
            <span
              className="rsbcSliderCircle"
              style={{ background: this.props.color }}
            ></span>
          </div>
          <div className="rsbcText">
            <b>{this.getText()}</b>
          </div>
        </div>
      </div>
    );
  }
}
