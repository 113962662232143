import { useState } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import startWorkVideo from "../../assets/videos/startwork.mp4";
import changeWorkTypeVideo from "../../assets/videos/changeWorkType.mp4";
import { Player } from "video-react";
import { useTranslation } from "react-i18next";

const Guides = () => {
  const { t } = useTranslation();

  const [value, setValue] = useState("start");

  const handleChange = (value) => {
    setValue(value);
  };

  const sideBar = [
    { label: t("instruction.startingWork"), value: "start" },
    { label: t("instruction.switchWorkType"), value: "change" },
  ];

  return (
    <Box className="mt-5">
      <TabContext value={value}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 bg-light">
              <h1 className="text-center text-red py-2">
                {t("instruction.instructions")}
              </h1>
              <div className="sidebar">
                <div className="sidebar-content">
                  <List>
                    {sideBar.map((item) => (
                      <ListItem
                        button
                        onClick={() => handleChange(item.value)}
                        key={item.value}
                      >
                        <ListItemText primary={item.label} />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </div>
            </div>
            <div className="col-md-9 mt-3">
              <div className="content">
                <TabPanel value="start">
                  <h3>{t("instruction.startingWork")}</h3>
                  <Player playsInline src={startWorkVideo} aspectRatio="4:3" />
                </TabPanel>
                <TabPanel value="change">
                  <h3>{t("instruction.switchWorkType")}</h3>
                  <Player
                    playsInline
                    src={changeWorkTypeVideo}
                    aspectRatio="4:3"
                  />
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </TabContext>
    </Box>
  );
};

export default Guides;
