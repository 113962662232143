import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

const Routing = ({ permission }) => {
  const routeComponents = permission.map(({ path, component }, key) => (
    <Route exact path={path} element={component} key={key} />
  ));
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>{routeComponents}</Routes>
    </Suspense>
  );
};

export default Routing;
