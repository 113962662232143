import React from 'react';
import { useSelector } from 'react-redux';

import BeatLoader from 'react-spinners/BeatLoader';
import {
  convertIsoToLocalDate,
  extractTime,
  calcDifferenceTime,
  countTotalWorkTime,
} from '../../../../utils/DateTime';
import {
  getWarehouseNameByID,
  getWorkerNameByID,
} from '../../../../utils/getName';
import { useTranslation } from 'react-i18next';

const RenderReportSection = ({ loading }) => {
  const { t } = useTranslation();
  const reports = useSelector((state) => state.report?.TimeInterval);
  if (reports) {
    reports.sort((a, b) => {
      return new Date(b.check_in) - new Date(a.check_in);
    });
  }
  const warehouses = useSelector((state) => state.warehouses);
  const workers = useSelector((state) => state.users);

  return (
    <>
      <div className="d-flex justify-content-center">
        <BeatLoader color="#de0e19" loading={loading} size={24} />
      </div>
      <div className="table-responsive-sm">
        <table className="table">
          {reports && reports.length > 0
            ? reports.map((shift) => (
                <React.Fragment key={`${shift.check_in}${shift.worker}`}>
                  <thead>
                    <tr className="table-info">
                      <td colSpan="6">
                        {t('report.employee')}:{' '}
                        <b>{getWorkerNameByID(shift.worker, workers)}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        {t('report.startWorking')}:{' '}
                        <b>
                          {convertIsoToLocalDate(shift.check_in)}{' '}
                          {extractTime(shift.check_in)}
                        </b>
                      </td>
                      <td colSpan="2">
                        {t('report.warehouse')}:{' '}
                        <b>
                          {getWarehouseNameByID(shift.warehouse, warehouses)}
                        </b>
                      </td>
                      <td colSpan="2">
                        {t('report.stopWorking')}:{' '}
                        <b>{extractTime(shift.check_out)}</b>
                      </td>
                    </tr>
                    <tr className="text-danger">
                      <th>{t('report.task')}</th>
                      <th>{t('report.workTypeStart')}</th>
                      <th>{t('report.principal')}</th>
                      <th>{t('report.workType')}</th>
                      <th>{t('report.workTypeEnd')}</th>
                      <th>{t('report.workingTime')}</th>
                    </tr>
                  </thead>
                  <tbody key={shift.check_out}>
                    {shift.tasks.map((task) => (
                      <tr key={task.start}>
                        <td></td>
                        <td>{extractTime(task.start)}</td>
                        <td>{task.principal_name}</td>
                        <td>{t(`${task.work_type_name}`)}</td>
                        <td>{extractTime(task.end)}</td>
                        <td>{calcDifferenceTime(task.start, task.end)}</td>
                      </tr>
                    ))}

                    <tr>
                      <td colSpan="6" className="table-secondary">
                        {t('report.summary')}
                      </td>
                    </tr>

                    {shift.workTypeTotal.map((e) => (
                      <tr key={e.work_type}>
                        <td />
                        <td />
                        <td />
                        <td>{t(`${e.work_type_name}`)}</td>
                        <td>{t('report.total')}</td>
                        <td>{countTotalWorkTime(e.total)}</td>
                      </tr>
                    ))}
                    <tr className="table-success">
                      <td />
                      <td />
                      <td />
                      <td>{t('report.totalWorkingTime')}</td>
                      <td>{t('report.total')}</td>
                      <td>
                        {calcDifferenceTime(shift.check_in, shift.check_out)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">-</td>
                    </tr>
                  </tbody>
                </React.Fragment>
              ))
            : reports !== null && (
                <thead>
                  <tr>
                    <td>{t('report.notFound')}</td>
                  </tr>
                </thead>
              )}
        </table>
      </div>
    </>
  );
};

export default RenderReportSection;
