import { getProductsApi } from "../api/inventory";
import { getDoc, doc } from "firebase/firestore";
import { locationsCollectionRef, productsCollectionRef } from "../db/firestore";
import { formatDoc } from "../utils/formatFirestore";

export function getProducts() {
  return async (dispatch) => {
    try {
      let result = [];
      const response = await getProductsApi();
      Promise.all(
        response.map(async (item) => {
          const productId = item.product.id;
          const rproduct = await getDoc(doc(productsCollectionRef, productId));
          const product = formatDoc(rproduct);
          const locationId = item.locations.id;
          const rlocations = await getDoc(
            doc(locationsCollectionRef, locationId)
          );
          const locations = formatDoc(rlocations);
          result.push({ ...item, product, locations });
        })
      ).then(() => {
        dispatch({
          type: "GETPRODUCTS",
          payload: result,
        });
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };
}
