import { releasesCollectionRef } from '../db/firestore';
import { getDocs } from 'firebase/firestore';
import { formatDocs } from '../utils/formatFirestore';
import { query, orderBy, limit } from 'firebase/firestore';

export const getReleasesApi = async () => {
  const q = query(
    releasesCollectionRef,
    orderBy('time_stamp', 'desc'),
    limit(3)
  );
  const response = await getDocs(q);
  return formatDocs(response);
};
