import { isDate, sortDate } from './formatDates';
import { fDate } from './formatTime';

export const getDates = (list) => {
  const dates = [];

  if (!list) return dates;
  list.forEach((element) => {
    let str = element.description;
    str = str
      .split('.')
      .map((word) => {
        return parseInt(word);
      })
      .join('.');

    if (isDate(str) && !dates.includes(str)) {
      dates.push(element.description);
    }
  });

  if (dates.length === 1) {
    return [dates[0], dates[0]];
  }
  let sorteddays;
  try {
    sorteddays = sortDate(dates);
    return sorteddays;
  } catch (e) {
    throw new Error('Sort Dates:' + e.message);
  }
};

const HOLIDAY_DAYS = [
  '01.01.xxxx',
  '06.01.xxxx',
  '01.05.xxxx',
  '06.12.xxxx',
  '24.12.xxxx',
  '25.12.xxxx',
  '26.12.xxxx',
  '15.04.2022',
  '18.04.2022',
  '26.05.2022',
  '24.06.2022',
  '25.06.2022',
  '05.11.2022',
  '02.04.2021',
  '05.04.2021',
  '13.05.2021',
  '25.06.2021',
  '26.06.2021',
  '06.11.2021',
];

export const isHoliday = (date) => {
  const dateStr = fDate(date);
  if (HOLIDAY_DAYS.includes(dateStr)) return true;
  const everyYear = HOLIDAY_DAYS.filter((day) => day.includes('xxxx'));
  const thisYear = everyYear.map((day) => {
    return day.replace('xxxx', date.getFullYear());
  });
  if (thisYear.includes(dateStr)) return true;
  return false;
};
