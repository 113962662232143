import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { userLogout } from '../../actions/userActions';
import {
  ExternalPermission,
  InternalPermission,
  ManagerPermission,
  ExternalManagerPermission,
  ApplicantPermission,
} from '../../configs/RolePermission';
import { Link } from 'react-router-dom';
import Routing from '../Others/Routing';
import logo from '../../assets/images/DTH-logo-valkoinen.png';
import { useNavigate } from 'react-router-dom';

import Notification from '../Others/Notification';
import ChangeLanguageBar from '../Others/Language';
import { useTranslation } from 'react-i18next';
import NotificationsPopover from '../../layouts/dashboard/header/notification/NotificationsPopover';
import { Stack } from '@mui/material';

const Navigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);
  const [expanded, setExpanded] = useState(false);

  const logout = () => {
    dispatch(userLogout());
    navigate('/');
  };
  let Permission = null;
  if (user.user_type === 'ET') {
    Permission = ExternalPermission;
  } else if (user.user_type === 'IT') {
    Permission = InternalPermission;
  } else if (user.user_type === 'EM') {
    Permission = ExternalManagerPermission;
  } else if (user.user_type === 'MN') {
    Permission = ManagerPermission;
  } else if (user.user_type === 'AP') {
    Permission = ApplicantPermission;
  }

  return (
    <>
      <Notification />
      <Navbar expand="lg" className="topbar" expanded={expanded}>
        <div className="container">
          <Navbar.Brand>
            <Link
              to="/"
              onClick={() => setExpanded(expanded ? false : 'expanded')}
            >
              <img src={logo} alt="logo" className="loginlogo" />
            </Link>
          </Navbar.Brand>

          <Stack direction="row" spacing={2} className="d-flex d-lg-none ">
            <NotificationsPopover />

            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setExpanded(expanded ? false : 'expanded')}
            />
          </Stack>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav ref={React.createRef()}>
              {Permission.map(
                (p) =>
                  p.display && (
                    <Link
                      to={`${p.path}`}
                      className="nav-link"
                      key={p.path}
                      onClick={() => setExpanded(expanded ? false : 'expanded')}
                    >
                      {t(`navigation.${p.displayText}`)}
                    </Link>
                  )
              )}
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <div className="d-none d-lg-block mr-3">
                <NotificationsPopover />
              </div>
              <button className="btn btn-logout font-w600" onClick={logout}>
                {t('login.logout')}
              </button>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <ChangeLanguageBar userEmail={user.email} />
      <div className="container">
        <Routing permission={Permission} />
      </div>
    </>
  );
};

export default Navigation;
