import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { managerReportSearch } from "../../../../actions/reportAction";
import { getUsers } from "../../../../actions/userActions";
import {
  getWarehouse,
  getWarehousePrincipals,
  getPrincipalsTasks,
} from "../../../../actions/warehouseActions";
import { sortObject } from "../../../HelperFunctions/Others";
import { useTranslation } from "react-i18next";

const SearchSection = ({ setLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [warehouse, setWarehouse] = useState("All");
  const [principal, setPrincipal] = useState("All");
  const [task, setTask] = useState("All");
  const [worker, setWorker] = useState("All");

  useEffect(() => {
    dispatch(getWarehouse());
    dispatch(getUsers());
  }, [dispatch]);

  const warehouses = sortObject(
    useSelector((state) => state.warehouses),
    "name"
  );
  const principals = sortObject(
    useSelector((state) => state.principal),
    "name"
  );
  const tasks = sortObject(
    useSelector((state) => state.principalTasks),
    "title"
  );
  const users = sortObject(
    useSelector((state) => state.users),
    "first_name"
  );

  const handleChangeWorker = (e) => {
    const workerId = e.target.value;
    setWorker(workerId);
  };

  const handleChangeWarehouse = (e) => {
    const warehouseId = e.target.value;
    setWarehouse(warehouseId);
    setPrincipal("All");
    setTask("All");
    if (warehouseId !== "All") {
      dispatch(getWarehousePrincipals(warehouseId));
    }
  };
  const handleChangePrincipal = (e) => {
    const principalId = e.target.value;
    setPrincipal(principalId);
    setTask("All");
    if (principalId !== "All") {
      dispatch(getPrincipalsTasks(warehouse, principalId));
    }
  };

  const search = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = {
      worker,
      warehouse,
      principal,
      task,
      startDate,
      endDate,
    };
    await dispatch(managerReportSearch(data));
    setLoading(false);
  };

  return (
    <form onSubmit={search}>
      <div className="form-group mt-3">
        <div className="form-row ">
          <div className="col-lg-6 form-group">
            <label htmlFor="start">
              <b>From</b>
            </label>
            <input
              className="form-control"
              value={startDate}
              onChange={({ target }) => setStartDate(target.value)}
              type="date"
              required
            />
          </div>
          <div className="col-lg-6 form-group">
            <label htmlFor="end">
              <b>To</b>
            </label>
            <input
              className="form-control"
              value={endDate}
              onChange={({ target }) => setEndDate(target.value)}
              type="date"
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="worker">
            <b>{t("common.selectEmployee")}</b>
          </label>
          <select
            onChange={handleChangeWorker}
            className="form-control"
            id="worker"
          >
            <option value="All">Kaikki</option>
            {users &&
              users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.first_name} {user.last_name}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="warehouse">
            <b>Valitse työpaikka, kustannuspaikka</b>
          </label>
          <select
            onChange={handleChangeWarehouse}
            className="form-control"
            id="warehouse"
          >
            <option value="All">Kaikki</option>
            {warehouses &&
              warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <div className="form-row">
            {warehouse !== "All" && (
              <div className="col-lg-6 form-group">
                <label htmlFor="principal">
                  <b>Valitse päämies</b>
                </label>
                <select
                  onChange={handleChangePrincipal}
                  className="form-control"
                  id="principal"
                >
                  <option value="All">Kaikki</option>
                  {principals &&
                    principals.map((principal) => (
                      <option key={principal.id} value={principal.id}>
                        {principal.name}
                      </option>
                    ))}
                </select>
              </div>
            )}

            {principal !== "All" ? (
              <div className="col-lg-6 form-group">
                <label htmlFor="task">
                  <b>Valitse työlaji</b>
                </label>
                <select
                  onChange={({ target }) => setTask(target.value)}
                  className="form-control"
                  id="task"
                >
                  <option value="All">Kaikki</option>
                  {tasks &&
                    tasks.map((task) => (
                      <option key={task.id} value={task.id}>
                        {task.title}
                      </option>
                    ))}
                </select>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="d-flex">
          <button type="submit" className="btn btn-primary">
            Search
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchSection;
