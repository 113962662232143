import { useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListSubheader,
} from '@mui/material';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// components
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
import MenuPopover from '../../../../components/menu-popover';
import { IconButtonAnimate } from '../../../../components/animate';
import {
  getNotifications,
  markAllNotificationReaded,
} from '../../../../api/notification';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import NotificationItem from './NotificationItem';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [openPopover, setOpenPopover] = useState(null);

  const [notifications, setNotifications] = useState([]);

  const user = useSelector((state) => state.user);
  const query = useQuery(
    ['notification'],
    async () => await getNotifications(user.id)
  );

  const mutation = useMutation(markAllNotificationReaded, {
    onSuccess: () => {
      queryClient.invalidateQueries(['notification']);
    },
  });
  useEffect(() => {
    if (query.data) {
      setNotifications(query.data);
    }
  }, [query.data]);
  const totalUnRead = notifications.filter(
    (item) => item.isRead === false
  ).length;

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = () => {
    mutation.mutate(user.id);
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="info">
          <Iconify icon="eva:bell-fill" sx={{ color: 'white' }} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 360, p: 0 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">
              {t('notification.notifications')}
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('notification.You-have')} {totalUnRead}{' '}
              {t('notification.unread-messages')}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title={t('notification.Mark-all-as-read')}>
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: 'overline' }}
              >
                {t('notification.unread')}
              </ListSubheader>
            }
          >
            {notifications
              .filter((item) => item.isRead === false)
              .slice(0, 5)
              .map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  onClosePopover={handleClosePopover}
                />
              ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: 'overline' }}
              >
                {t('notification.read')}
              </ListSubheader>
            }
          >
            {notifications
              .filter((item) => item.isRead === true)
              .slice(0, 5 - totalUnRead)
              .map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  onClosePopover={handleClosePopover}
                />
              ))}
          </List>
        </Scrollbar>

        {/*
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box>

        */}
      </MenuPopover>
    </>
  );
}
