import { useState, useEffect } from "react";

const CountTime = ({ startTime }) => {
  // startTime type Timestamp
  const calculateTime = () => {
    const difference = new Date().getTime() - startTime;
    let time = {};
    if (difference > 0) {
      time = {
        d: Math.floor(difference / (1000 * 60 * 60 * 24)),
        h: Math.floor((difference / (1000 * 60 * 60)) % 24),
        m: Math.floor((difference / 1000 / 60) % 60),
        s: Math.floor((difference / 1000) % 60),
      };
    }
    return time;
  };
  const [time, setTime] = useState(calculateTime());

  useEffect(() => {
    let isMounted = true;

    setTimeout(() => {
      if (isMounted) setTime(calculateTime());
    }, 1000);

    return () => {
      isMounted = false;
    };
  });

  const timerComponents = [];

  Object.keys(time).forEach((interval) => {
    if (!time[interval]) {
      return;
    }
    timerComponents.push(
      <span key={interval}>
        {time[interval]} {interval}{" "}
      </span>
    );
  });

  return <>{timerComponents.length && timerComponents}</>;
};

export default CountTime;
