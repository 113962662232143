export const getWarehouseNameByID = (warehouseID, warehouses) => {
  const warehouse = warehouses.find((e) => e.id === warehouseID);
  return warehouse.name;
};
export const getWorkerNameByID = (workerID, workers) => {
  if (workers) {
    const worker = workers.find((e) => e.id === workerID);
    const fullName = `${worker.first_name} ${worker.last_name}`;
    return fullName;
  }
  return null;
};
