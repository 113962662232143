import { postOffDay } from '../api/offday';
import { createNotification } from '../api/notification';

export const createOffday = async (data) => {
  const submitData = {
    employee: data.employee.id,
    reason: data.reason,
    reasonType: data.reasonType,
    startDate: data.startDate,
    endDate: data.endDate,
    sender: data.sender.id,
    createdAt: new Date(),
    accepted: false,
    processed: false,
    status: 'pending',
  };

  const notificationData = {
    createdAt: new Date(),
    description: `Is-offday`,
    type: 'offday',
    isRead: false,
    title: data.employee.name,
  };

  const response = await postOffDay(submitData);
  const to_users = [10, 17, 19];
  to_users.forEach(async (to_user) => {
    await createNotification({ ...notificationData, user_id: to_user });
  });

  return response;
};
