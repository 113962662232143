import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';

import notificationReducer from './reducer/notificationReducer';
import userReducer from './reducer/userReducer';
import usersReducer from './reducer/usersReducer';
import warehouseReducer from './reducer/warehouseReducer';
import scheduleReducer from './reducer/scheduleReducer';
import shiftReducer from './reducer/shiftReducer';
import reportReducer from './reducer/reportReducer';
import principalTasksReducer from './reducer/principalTasksReducer';
import PrincipalReducer from './reducer/PrincipalReducer';
import InventoryReducer from './reducer/InventoryRecucer';
import PerformanceReducer from './reducer/PerformanceReducer';

const reducer = combineReducers({
  message: notificationReducer,
  user: userReducer,
  users: usersReducer,
  warehouses: warehouseReducer,
  principal: PrincipalReducer,
  tasks: scheduleReducer,
  report: reportReducer,
  shift: shiftReducer,
  principalTasks: principalTasksReducer,
  inventory: InventoryReducer,
  performance: PerformanceReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
