import React from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

const Notification = () => {
  const notification = useSelector((state) => state.message);
  const { enqueueSnackbar } = useSnackbar();

  if (notification) {
    if (notification[1] === "error") {
      enqueueSnackbar(notification[0], { variant: "error" });
    } else {
      enqueueSnackbar(notification[0], { variant: "success" });
    }
    return <></>;
  }
  return <></>;
};

export default Notification;
