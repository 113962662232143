import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
} from '@mui/material';

import { format, eachDayOfInterval, startOfTomorrow } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { getWarehousePrincipals } from '../../../actions/warehouseActions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const validationSchema = Yup.object().shape({
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date()
    .when(
      'startDate',
      (startDate, schema) => startDate && schema.min(startDate)
    )
    .nullable(),
  startTime: Yup.number().required('Start time is required'),
  endTime: Yup.number().required('End time is required'),
  warehouse: Yup.object().required('Warehouse is required'),
  principal: Yup.object().required('Principal is required'),
});

const AddShiftModal = ({ open, handleClose, data, setData }) => {
  const dispatch = useDispatch();

  const warehouse = useSelector((state) => state.warehouses) || [];
  const principals = useSelector((state) => state.principal) || [];

  const handleSubmit = (values) => {
    if (!values.endDate) {
      setData(
        data.concat({
          date: format(values.startDate, 'dd.MM'),
          warehouse: values.warehouse,
          principal: values.principal,
          start: format(values.startTime, 'HH:mm'),
          end: format(values.endTime, 'HH:mm'),
        })
      );
    } else {
      const dates = eachDayOfInterval({
        start: values.startDate,
        end: values.endDate,
      });
      const copyData = data;

      dates.forEach((date) => {
        copyData.push({
          date: format(date, 'dd.MM'),
          warehouse: values.warehouse,
          principal: values.principal,
          start: format(values.startTime, 'HH:mm'),
          end: format(values.endTime, 'HH:mm'),
        });
      });

      setData(copyData);
    }
    handleClose();
    return;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="mb-3"
        >
          Add new shift
        </Typography>
        <Formik
          initialValues={{
            startDate: new Date(startOfTomorrow()),
            endDate: null,
            startTime: 1655175600000,
            endTime: 1655204400000,
            warehouse: null,
            principal: null,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            try {
              handleSubmit(values);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, errors, setFieldValue, isSubmitting }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <DatePicker
                    id="startDate"
                    name="startDate"
                    label="startDate"
                    format="dd.MM.yyyy"
                    value={values.startDate}
                    onChange={(value) => {
                      setFieldValue('startDate', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: '100%' }}
                        {...params}
                        error={Boolean(errors.startDate)}
                        helperText={errors.startDate}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DatePicker
                    id="endDate"
                    name="endDate"
                    label="endDate"
                    value={values.endDate}
                    onChange={(value) => {
                      setFieldValue('endDate', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: '100%' }}
                        {...params}
                        error={Boolean(errors.endDate)}
                        helperText={errors.endDate}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TimePicker
                    sx={{ width: 100 }}
                    id="startTime"
                    name="startTime"
                    label="startTime"
                    ampm={false}
                    value={values.startTime}
                    onChange={(value) => {
                      setFieldValue('startTime', Date.parse(value));
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: '100%' }}
                        {...params}
                        error={Boolean(errors.startTime)}
                        helperText={errors.startTime}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TimePicker
                    sx={{ width: 100 }}
                    id="endTime"
                    name="endTime"
                    label="endTime"
                    ampm={false}
                    value={values.endTime}
                    onChange={(value) => {
                      setFieldValue('endTime', Date.parse(value));
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: '100%' }}
                        {...params}
                        error={Boolean(errors.endTime)}
                        helperText={errors.endTime}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    id="warehouse"
                    name="warehouse"
                    label="warehouse"
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    options={warehouse}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Warehouse"
                        error={Boolean(errors.warehouse)}
                        helperText={errors.warehouse}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setFieldValue('warehouse', newValue);
                      if (newValue) {
                        dispatch(getWarehousePrincipals(newValue?.id));
                      }
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    id="principal"
                    name="principal"
                    label="principal"
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    options={principals}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Principal"
                        error={Boolean(errors.principal)}
                        helperText={errors.principal}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setFieldValue('principal', newValue);
                    }}
                  />
                </Grid>

                <Grid item md={12} container justifyContent="flex-end">
                  <Button color="error" variant="contained" type="submit">
                    {isSubmitting ? 'Adding...' : 'Add'}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddShiftModal;
