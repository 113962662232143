import { Routes, Route } from 'react-router-dom';
import ForgotPassword from './Login/ForgotPassword';
import LoginForm from './Login/LoginForm';
import Registration from './Login/Registration';
import Notification from '../components/Others/Notification';
import Navigation from '../components/Others/Navigation';
import ChangeLanguageBar from './Others/Language';

const Login = () => {
  return (
    <div>
      <Navigation />
      <ChangeLanguageBar />
      <Notification />
      <div className="container d-flex align-items-center justify-content-center my-5">
        <Routes>
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/" element={<LoginForm />} />
        </Routes>
      </div>
    </div>
  );
};

export default Login;
