import React from "react";
import logo from "../../assets/images/DTH-logo-valkoinen.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Navigation = () => {
  const user = useSelector((state) => state.user);
  return (
    <>
      {!user && (
        <div className="bg-red d-flex justify-content-center">
          <Link to="/">
            <img src={logo} alt="logo" className="loginlogo" />
          </Link>
        </div>
      )}
    </>
  );
};

export default Navigation;
