import shiftApi from '../api/shift';
import reportApi from '../api/report';

import { setMessage } from './notificationActions';
import { getCheckIn, getCheckOut } from '../utils/shiftTimeCompare';

export const getTasks = () => {
  return async (dispatch) => {
    const tasks = await shiftApi.getTasks();
    dispatch({
      type: 'GETTASK',
      tasks,
    });
  };
};

export const addShift = (warehouse, worker) => {
  return async (dispatch) => {
    const time = new Date();
    const is_working = true;
    const shifts = await shiftApi.getShiftByWorkerID(worker);
    const found = shifts.find((e) => e.is_working === true);

    if (!found) {
      await shiftApi.postShift(worker, warehouse, time, is_working);
      const shifts = await shiftApi.getShiftByWorkerID(worker);
      dispatch({
        type: 'GETSHIFT',
        payload: shifts,
      });
    } else {
      dispatch(
        setMessage(
          'You already started working, try to refresh the page',
          'error',
          60
        )
      );
    }
  };
};

export const getShift = () => {
  return async (dispatch) => {
    const schedules = await shiftApi.getShift();
    dispatch({
      type: 'GETSHIFT',
      payload: schedules,
    });
  };
};

export const getWorkingShift = () => {
  return async (dispatch) => {
    const schedules = await reportApi.getWorkingShift();
    schedules.forEach((sched) => {
      let task = sched.tasks.find((task) => task.end === null);
      if (task) {
        sched.tasks = task;
      } else {
        sched.tasks = { principal_name: '', work_type_name: '' };
      }
    });
    dispatch({
      type: 'GETSHIFT',
      payload: schedules,
    });
  };
};

export const getShiftByWorkerID = (id) => {
  return async (dispatch) => {
    const schedules = await shiftApi.getShiftByWorkerID(id);
    dispatch({
      type: 'GETSHIFT',
      payload: schedules,
    });
  };
};

export const getShiftTask = (id) => {
  return async (dispatch) => {
    const tasks = await shiftApi.getShiftTask(id);
    dispatch({
      type: 'GETTASK',
      payload: tasks,
    });
  };
};

export const getShiftsTasks = (id) => {
  return async (dispatch) => {
    const tasks = await shiftApi.getShiftTask(id);
    dispatch({
      type: 'GETSHIFTSTASKS',
      payload: tasks,
    });
  };
};

export const postShiftTask = (warehouseID, workType) => {
  const time = new Date();
  return async (dispatch) => {
    const tasks = await shiftApi.postShiftTask(warehouseID, workType, time);
    dispatch({
      type: 'ADDTASK',
      payload: tasks,
    });
  };
};

export const stopWork = (id) => {
  return async (dispatch) => {
    const time = new Date();
    const data = {
      check_out: time,
      is_working: false,
    };
    await shiftApi.patchShift(id, data);
  };
};

export const addSpeedRate = (shiftId, speedRate, unit) => {
  return async () => {
    const data = {
      shift_id: shiftId,
      speed_rate: speedRate,
      unit,
      time_stamp: new Date(),
    };
    await shiftApi.postSpeedRate(data);
  };
};

export const startAssignedShift = (assigned) => {
  return async (dispatch) => {
    const time = new Date();
    const data = {
      check_in: assigned.check_in ? assigned.check_in : time,
      check_out: null,
      is_working: true,
    };
    await shiftApi.patchShift(assigned.id, data);
    const shifts = await shiftApi.getShiftByWorkerID(assigned.worker);
    dispatch({
      type: 'GETSHIFT',
      payload: shifts,
    });
  };
};

export const switchWork = (shiftID, taskID, workType) => {
  return async (dispatch) => {
    const time = new Date();
    const data = {
      shiftID,
      taskID,
      workType,
      time,
    };
    const response = await shiftApi.putShiftTask(data);
    dispatch({
      type: 'PUTTASK',
      payload: response,
    });
  };
};

export const createShifts = (workers, data) => {
  return async (dispatch) => {
    for (const worker of workers) {
      for (const shift of data) {
        const currentYear = new Date().getFullYear();
        const dayAndMonth = shift.date.split('.');
        const startTime = shift.start.split(':');
        const endTime = shift.end.split(':');

        const assignedStart = new Date(
          currentYear,
          dayAndMonth[1] - 1,
          dayAndMonth[0],
          startTime[0],
          startTime[1]
        );
        const assignedEnd = new Date(
          currentYear,
          dayAndMonth[1] - 1,
          dayAndMonth[0],
          endTime[0],
          endTime[1]
        );

        const workerID = worker.id;
        const warehouse = shift.warehouse.id;

        await shiftApi.createShift(
          workerID,
          warehouse,
          assignedStart,
          assignedEnd
        );
      }
    }
  };
};

export const updateAssignedTime = (times, shiftID) => {
  return async (dispatch) => {
    const data = {
      assigned_start: times.start,
      assigned_end: times.end,
      is_extra: times.extra,
    };
    let updated_shift = await shiftApi.patchShift(shiftID, data);

    updated_shift.check_in = getCheckIn(
      updated_shift.check_in,
      updated_shift.assigned_start
    );
    updated_shift.check_out = getCheckOut(
      updated_shift.check_out,
      updated_shift.assigned_end,
      updated_shift.is_extra
    );

    dispatch({
      type: 'UPDATEASSIGNEDTIME',
      payload: updated_shift,
    });
  };
};
