import { Link } from 'react-router-dom';
import packageJson from '../../../package.json';
import { useSelector } from 'react-redux';

const Footer = () => {
  const user = useSelector((state) => state.user);
  return (
    <div className="container">
      <footer className="d-flex flex-wrap py-3 my-4 justify-content-between border-top">
        <span className="text-muted">© 2022 DTH-Palvelut Oy</span>
        {user.user_type === 'MN' ? (
          <Link
            to="/setting/about"
            className="text-muted"
            style={{ textDecoration: 'none' }}
          >
            <span className="text-muted">{packageJson.version}</span>
          </Link>
        ) : (
          <span className="text-muted">{packageJson.version}</span>
        )}
      </footer>
    </div>
  );
};

export default Footer;
