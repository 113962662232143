import { useState } from 'react';
import {
  Card,
  Button,
  Select,
  MenuItem,
  Container,
  TextField,
  Autocomplete,
  Grid,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import useSettings from '../../../hooks/useSettings';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTeam } from '../../../api/offday';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createOffday } from '../../../actions/offdayActions';
import { useNavigate } from 'react-router-dom';
import { getTeamID } from '../../../utils/getTeamID';

const REASONS = [
  {
    value: 'No job offer',
    label: 'No job offer',
  },
  {
    value: 'Personal reason',
    label: 'Personal reason',
  },
];

const validationSchema = yup.object({
  employee: yup
    .object()
    .shape({
      id: yup.string().required('Employee is required'),
      name: yup.string().required('Employee is required'),
    })
    .required('Employee is required')
    .nullable()
    .test({
      name: 'employee',
      message: 'Employee is required',
      test: (value) => value !== null,
    }),
});

const OffDay = () => {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [team, setTeam] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const team = await getTeam(getTeamID(user.id));
      setTeam(team);
    }
    fetchData();
  }, [user.id]);

  const formik = useFormik({
    initialValues: {
      employee: null,
      reason: null,
      reasonType: 'unpaid',
      startDate: new Date(),
      endDate: new Date(),
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await createOffday({
        ...values,
        sender: { id: user.id, name: `${user.first_name} ${user.last_name}` },
      });
      navigate('/');
      console.log(values);
    },
  });

  return (
    <Page title="Shifts: Holiday">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Holiday"
          links={[{ name: 'Dashboard', href: '/' }, { name: 'Inventory' }]}
        />
      </Container>

      <Card sx={{ padding: 3, minHeight: '100%' }}>
        <form onSubmit={formik.handleSubmit} className="d-flex g-3 flex-column">
          <Autocomplete
            id="employee"
            name="employee"
            options={team}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                error={Boolean(formik.errors.employee)}
                helperText={formik.errors.employee}
                {...params}
                label="Employee"
              />
            )}
            value={formik.values.employee}
            onChange={(event, value) => {
              formik.setFieldValue('employee', value);
            }}
          />

          <Select
            id="reasonType"
            value={formik.values.reasonType}
            onChange={(event) => {
              formik.setFieldValue('reasonType', event.target.value);
            }}
          >
            <MenuItem value="unpaid">Unpaid day-off</MenuItem>
            <MenuItem value="scheduled">Scheduled holiday</MenuItem>
          </Select>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DatePicker
                id="startDate"
                name="startDate"
                label="startDate"
                format="dd.MM.yyyy"
                value={formik.values.startDate}
                onChange={(value) => {
                  formik.setFieldValue('startDate', value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: '100%' }}
                    {...params}
                    error={Boolean(formik.errors.startDate)}
                    helperText={formik.errors.startDate}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                id="endDate"
                name="endDate"
                label="endDate"
                value={formik.values.endDate}
                onChange={(value) => {
                  formik.setFieldValue('endDate', value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: '100%' }}
                    {...params}
                    error={Boolean(formik.errors.endDate)}
                    helperText={formik.errors.endDate}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Autocomplete
            id="reason"
            options={REASONS}
            freeSolo
            value={formik.values.reason}
            onInputChange={(event, value) => {
              console.log(value);
              formik.setFieldValue('reason', value);
            }}
            renderInput={(params) => <TextField {...params} label="Reason" />}
          />

          <Button variant="contained" type="submit">
            Submit
          </Button>
        </form>
      </Card>
    </Page>
  );
};

export default OffDay;
