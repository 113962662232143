const reportReducer = (state = null, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return null;
    case 'GETSHIFTSTASKS':
      if (!state) {
        return action.payload;
      }
      return state.concat(action.payload);
    case 'SEARCHREPORT':
      return { ...state, TimeInterval: action.payload };
    case 'WAREHOUSESHARES':
      return { ...state, warehouseShares: action.payload };
    case 'LATEST7DAYS':
      return { ...state, LatestWeek: action.payload };
    case 'TODAYSHIFTS':
      return { ...state, TodayShifts: action.payload };
    case 'SETSEACHPARAMS':
      return { ...state, SearchParams: action.payload };
    case 'UPDATEASSIGNEDTIME':
      const { payload } = action;
      const newState = { ...state };
      newState.TimeInterval = newState.TimeInterval.map((shift) => {
        if (shift.id === payload.id) {
          return { ...shift, ...payload };
        }
        return shift;
      });
      return newState;
    default:
      return state;
  }
};

export default reportReducer;
