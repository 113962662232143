import { getDocs, addDoc } from 'firebase/firestore';
import { teamsCollectionRef, offdayCollectionRef } from '../db/firestore';
import { formatDocs } from '../utils/formatFirestore';

export const getTeam = async (id) => {
  const teamResponse = await getDocs(teamsCollectionRef(id));
  return formatDocs(teamResponse);
};

export const postOffDay = async (data) => {
  const response = await addDoc(offdayCollectionRef, data);
  return response;
};
