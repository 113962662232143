export const sortObject = (object, keyName) => {
  if (object) {
    object.sort((a, b) => {
      if (
        a[keyName].toLowerCase().replace(/\s/g, '') <
        b[keyName].toLowerCase().replace(/\s/g, '')
      ) {
        return -1;
      }
      if (
        a[keyName].toLowerCase().replace(/\s/g, '') >
        b[keyName].toLowerCase().replace(/\s/g, '')
      ) {
        return 1;
      }
      return 0;
    });
  }
  return object;
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const getWarehouseNameByID = (warehouseID, warehouses) => {
  if (warehouseID === 'All') return 'All';
  const id = parseInt(warehouseID);
  const warehouse = warehouses.find((e) => e.id === id);
  return warehouse.name;
};

export const getPerformanceLabel = (performance, unit) => {
  if (performance && !isNaN(performance)) {
    return `${performance} ${unit}`;
  }
  return ``;
};
