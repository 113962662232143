import { useState } from 'react';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { useTranslation } from 'react-i18next';

import { Card, Divider, Tabs, Tab } from '@mui/material';
import Iconify from '../../../../components/iconify/Iconify';
import { a11yProps } from '../../../../utils/a11yProps';
import Upload from '../../../../sections/@dashboard/sickleave/Upload';
import Reports from '../../../../sections/@dashboard/sickleave/Reports';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const Sickleave = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page>
      <HeaderBreadcrumbs
        heading={t('sickleave.header')}
        links={[
          { name: t('navigation.dashboard'), href: '/' },
          { name: t('navigation.sickleave') },
        ]}
      />

      <Card sx={{ mb: 5 }}>
        <Tabs
          variant="scrollable"
          aria-label="scrollable tabs"
          sx={{
            px: 2,
            bgcolor: 'background.neutral',
          }}
          value={value}
          onChange={handleChange}
        >
          <Tab
            label="Upload"
            {...a11yProps(0)}
            icon={<Iconify icon="ic:round-upload-file" />}
          />
          <Tab
            label="Reports"
            {...a11yProps(1)}
            icon={<Iconify icon="ic:round-receipt" />}
          />
        </Tabs>
        <Divider />

        <TabPanel value={value} index={0}>
          <Upload />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Reports />
        </TabPanel>
      </Card>
    </Page>
  );
};

export default Sickleave;
