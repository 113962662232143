import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogin } from "../../actions/userActions";
import { setMessage } from "../../actions/notificationActions";
import { useTranslation, Trans } from "react-i18next";

const LoginForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    dispatch(userLogin(email.toLowerCase(), password)).catch((err) => {
      console.error("exc", err);
      dispatch(setMessage("wrong username or password", "error", 5));
      setPassword("");
    });
  };

  return (
    <div className="p-5 rounded border col-lg-6 col-md-8 col-sm-12">
      <div className="mb-5">
        <h4>
          <Trans i18nKey="login.header">Sign in to your account</Trans>
        </h4>
      </div>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="email">{t("login.email")}</label>
          <input
            className="form-control"
            type="text"
            value={email}
            name="email"
            onChange={({ target }) => setEmail(target.value)}
            autoCapitalize="off"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">{t("login.password")}</label>
          <input
            className="form-control"
            type="password"
            value={password}
            name="Password"
            onChange={({ target }) => setPassword(target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary form-group">
          {t("login.login")}
        </button>
      </form>
      <h5>
        {t("login.noAccount")}{" "}
        <Link to="/registration/">{t("login.registration")}</Link>
      </h5>
      <h5>
        <Link to="/forgot_password/">{t("login.forgot")}</Link>
      </h5>
    </div>
  );
};

export default LoginForm;
