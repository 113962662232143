import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Button, Typography, Card, Stack } from '@mui/material';
import { differenceInMilliseconds } from 'date-fns';

import Page from '../../Page';
import Iconify from '../../iconify';
import HeaderBreadcrumbs from '../../HeaderBreadcrumbs';
import { timestampToDecimal } from '../../../utils/DateTime';

import AddShiftModal from '../../../sections/tools/create-shift/AddShiftModal';
import CreateShiftEmployee from '../../../sections/tools/create-shift/CreateShiftEmployee';
import CreateShiftCalendar from '../../../sections/tools/create-shift/CreateShiftCalendar';

import { getUsers } from '../../../actions/userActions';
import { getWarehouse } from '../../../actions/warehouseActions';
import { createShifts } from '../../../actions/shiftActions';

const CreateShifts = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getWarehouse());
  }, [dispatch]);

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const users = useSelector((state) => state.users) || [];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeEmployee = (e) => {
    setSelectedEmployee(e);
  };

  const handleSubmit = () => {
    let valid = false;
    if (!selectedEmployee) {
      enqueueSnackbar('Please select an employee', { variant: 'error' });

      return;
    }

    if (!data || data.length === 0) {
      enqueueSnackbar('Please add a new shift', { variant: 'error' });
      return;
    }

    if (selectedEmployee && data.length > 0) {
      valid = true;
    }

    if (valid) {
      dispatch(createShifts(selectedEmployee, data));
      setData([]);
      setSelectedEmployee(null);
      enqueueSnackbar('Shifts created successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Some thing is wrong', { variant: 'error' });
    }
  };

  const totalHours = () => {
    let total = 0;
    for (const shift of data) {
      const start = shift.start.split(':');
      const end = shift.end.split(':');
      total += differenceInMilliseconds(
        new Date(0, 0, 0, end[0], end[1]),
        new Date(0, 0, 0, start[0], start[1])
      );
      total -= 30 * 60 * 1000;
    }

    return timestampToDecimal(total / 1000);
  };

  return (
    <Page title="Create Shifts">
      <HeaderBreadcrumbs
        heading="Create Shifts"
        links={[
          { name: 'Dashboard', href: '/' },
          { name: 'Tools', href: '/tools' },
          { name: 'Create Shifts' },
        ]}
        action={
          <Button
            variant="contained"
            color="success"
            startIcon={
              <Iconify icon={'eva:plus-fill'} width={20} height={20} />
            }
            onClick={handleOpen}
          >
            Add New Shift
          </Button>
        }
      />

      <Card>
        <CreateShiftEmployee
          users={users}
          onChaneEmployee={handleChangeEmployee}
        />

        <CreateShiftCalendar data={data} />
      </Card>
      <Stack
        justifyContent="space-evenly"
        direction="row"
        spacing={2}
        sx={{ mt: 3 }}
      >
        <Typography variant="h6" color="initial">
          Total work time: {totalHours()}
        </Typography>

        <Button
          size="large"
          variant="contained"
          color="success"
          onClick={handleSubmit}
        >
          Send
        </Button>
      </Stack>

      <AddShiftModal
        open={open}
        handleClose={handleClose}
        data={data}
        setData={setData}
      />
    </Page>
  );
};

export default CreateShifts;
