import { useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
} from '@mui/material';

const SickleaveRejectDialog = ({ open, onClose, onReject, ...other }) => {
  const reasonRef = useRef();

  const handleSave = () => {
    onReject(reasonRef.current.value);
  };

  return (
    <Dialog fullWidth maxWidth={'sm'} open={open} onClose={onClose} {...other}>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            id="filled-multiline-static"
            label="Reason:"
            inputRef={reasonRef}
            multiline
            rows={4}
            variant="filled"
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="error" onClick={handleSave}>
          Reject
        </Button>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SickleaveRejectDialog;
