import { useState } from 'react';
import {
  Button,
  Divider,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';

import { FcDocument } from 'react-icons/fc';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fDate } from '../../../../utils/formatTime';
import { useSelector } from 'react-redux';
import Label from '../../../../components/label';
import Iconify from '../../../../components/iconify';
import MenuPopover from '../../../../components/menu-popover';
import ConfirmDialog from '../../../../components/confirm-dialog';
import { updateStatus } from '../../../../api/sickleave';

import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import ImageDialog from '../../../../components/image-dialog';
import SickleaveEditDialog from './SickleaveEditDialog';
import SickleaveRejectDialog from './SickleaveRejectDialog';

const SickleaveTableRow = ({ row, onDeleteRow }) => {
  const queryClient = useQueryClient();

  const {
    id,
    user_id,
    employee,
    start_date,
    end_date,
    status,
    days,
    filename,
    custom_id,
    reason,
  } = row;

  const currentUser = useSelector((state) => state.user);

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState(null);

  const [openImage, setOpenImage] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);

  const [openReject, setOpenReject] = useState(false);

  const [image, setImage] = useState(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  const storage = getStorage();

  const handleViewRow = async () => {
    setOpenImage(true);
    const x = await getDownloadURL(ref(storage, `sickleave/${filename}`));
    setImage(x);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const handleEditRow = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenReject = () => {
    setOpenReject(true);
  };

  const handleCloseReject = () => {
    setOpenReject(false);
  };

  const handleReject = (reason) => {
    mutation.mutate({
      uuid: id,
      status: 'rejected',
      accepted_by: currentUser.id,
      user_id: user_id,
      description: reason,
    });
    setOpenReject(false);
  };

  const mutation = useMutation(updateStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(['absence']);
    },
  });

  const getLabel = (status) => {
    return (
      <Label
        variant="soft"
        color={
          status === 'accepted'
            ? 'success'
            : status === 'rejected'
            ? 'error'
            : 'info'
        }
      >
        {status}
      </Label>
    );
  };

  return (
    <>
      <TableRow hover>
        <TableCell align="left">{custom_id}</TableCell>
        <TableCell onClick={handleViewRow} className="cursor-pointer">
          <Stack direction="row" alignItems="center" spacing={2}>
            <FcDocument size={42} />
            <Typography variant="subtitle2" noWrap>
              {employee}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell
          align="left"
          onClick={handleEditRow}
          className="cursor-pointer"
        >
          <Typography variant="subtitle2">{reason}</Typography>
        </TableCell>

        <TableCell
          align="center"
          onClick={handleEditRow}
          className="cursor-pointer"
        >
          {fDate(start_date)}
        </TableCell>
        <TableCell
          align="center"
          onClick={handleEditRow}
          className="cursor-pointer"
        >
          {fDate(end_date)}
        </TableCell>
        <TableCell align="center">{days}</TableCell>
        <TableCell align="center">{getLabel(status)}</TableCell>
        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          {status === 'pending' && (
            <>
              <Button
                variant="contained"
                sx={{ marginX: 1 }}
                onClick={() => {
                  mutation.mutate({
                    uuid: id,
                    status: 'accepted',
                    accepted_by: currentUser.id,
                    user_id: user_id,
                  });
                }}
              >
                Accept
              </Button>
              <Button
                variant="outlined"
                color="error"
                sx={{ marginX: 1 }}
                onClick={handleOpenReject}
              >
                Reject
              </Button>
            </>
          )}
          <IconButton
            color={openPopover ? 'inherit' : 'default'}
            onClick={handleOpenPopover}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            handleViewRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:eye-fill" />
          View
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />

      <ImageDialog open={openImage} onClose={handleCloseImage} image={image} />

      <SickleaveEditDialog
        open={openEdit}
        onClose={handleCloseEdit}
        startDate={start_date}
        endDate={end_date}
        employee={{ id: user_id, fullName: employee }}
        sickReason={reason}
        id={id}
      />

      <SickleaveRejectDialog
        open={openReject}
        onClose={handleCloseReject}
        onReject={handleReject}
      />
    </>
  );
};

export default SickleaveTableRow;
