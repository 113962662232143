const notificationReducer = (state = null, action) => {
  switch (action.type) {
    case "LOGOUT":
      return null;
    case "SET_MESSAGE":
      return action.message;
    default:
      return state;
  }
};

export default notificationReducer;
