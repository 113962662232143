import axios from 'axios';
import { getStorage, ref, uploadString } from 'firebase/storage';
import {
  doc,
  updateDoc,
  addDoc,
  getDocs,
  deleteDoc,
  query,
  orderBy,
  where,
} from 'firebase/firestore';
import { formatDocs } from '../utils/formatFirestore';
import { absencesCollectionRef } from '../db/firestore';
import { createNotification } from './notification';

const { REACT_APP_FIREBASE_API_KEY } = process.env;

const uploadFileApi = async (base64, filename) => {
  const storage = getStorage();
  const storageRef = ref(storage, `sickleave/${filename}`);

  return uploadString(storageRef, base64, 'data_url').then((snapshot) => {
    return snapshot;
  });
};

const postImage = async (body) => {
  const data = body;

  const response = await axios.post(
    `https://eu-vision.googleapis.com/v1/images:annotate?key=${REACT_APP_FIREBASE_API_KEY}`,
    data
  );
  return response.data;
};

const postAbsence = async (data) => {
  await addDoc(absencesCollectionRef, data);
  return;
};

export const getAbsences = async () => {
  const q = query(absencesCollectionRef, orderBy('time_stamp', 'desc'));
  const response = await getDocs(q);
  return formatDocs(response);
};

export const getUserAbsences = async (user_id) => {
  const q = query(absencesCollectionRef, where('user_id', '==', user_id));
  const response = await getDocs(q);
  return formatDocs(response);
};

export const getReportAbsences = async () => {
  const q = query(absencesCollectionRef, where('accepted', '==', true));
  const response = await getDocs(q);
  return formatDocs(response);
};

export const updateStatus = async (inputData) => {
  const acceptRef = doc(absencesCollectionRef, inputData.uuid);

  let data = {
    processed: true,
    accepted_time_stamp: new Date(),
    accepted_by: inputData.accepted_by,
    description: inputData.description || '',
  };

  const notificationData = {
    title: 'You-sick-leave-is-processed',
    description:
      inputData.status === 'accepted'
        ? 'Accepted'
        : `Rejected, ${inputData?.description}`,
    createdAt: new Date(),
    user_id: inputData.user_id,
    type: 'sickleave',
    isRead: false,
  };

  if (inputData.status === 'accepted') {
    data = { ...data, accepted: true, status: 'accepted' };
  } else {
    data = { ...data, accepted: false, status: 'rejected' };
  }

  await updateDoc(acceptRef, data);
  await createNotification(notificationData);
  return;
};

export const updateDates = async (inputData) => {
  const docRef = doc(absencesCollectionRef, inputData.uuid);
  const data = {
    start_date: inputData.start_date,
    end_date: inputData.end_date,
    reason: inputData.reason,
  };
  if (inputData.user_id) {
    data.user_id = inputData.user_id;
  }

  await updateDoc(docRef, data);
  return;
};

export const deleteAbsence = async (id) => {
  const deleteRef = doc(absencesCollectionRef, id);
  await deleteDoc(deleteRef);
  return;
};

// eslint-disable-next-line
export default {
  uploadFileApi,
  postImage,
  postAbsence,
};
