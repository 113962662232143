import { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useTranslation } from "react-i18next";
import CurrentWorking from "./CurrentWorking";

import CompletedShifts from "./EmCompletedShifts";

export default function EmWorksStatus() {
  const { t } = useTranslation();

  const [value, setValue] = useState("live");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box className="mt-5">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label={t("common.live")} value="live" />
            <Tab label={t("connon.done")} value="done" />
          </TabList>
        </Box>
        <TabPanel value="live">
          <CurrentWorking />
        </TabPanel>
        <TabPanel value="done">
          <CompletedShifts />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
