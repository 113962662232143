import sickleaveApi from '../api/sickleave';
import { getDates } from '../utils/getDates';
import { getDateByString } from '../utils/DateTime';
import { v4 as uuidv4 } from 'uuid';
import { createNotification } from '../api/notification';
import {
  getSickleaveCurrentID,
  updateSickleaveCurrentID,
} from '../api/statistics';

export const uploadFile = async (file, base64t, user) => {
  try {
    const base64 = base64t.split(',').slice(1).join(',');

    const body = {
      requests: [
        {
          image: {
            content: base64,
          },
          features: [
            {
              type: 'DOCUMENT_TEXT_DETECTION',
            },
          ],
        },
      ],
    };

    const response = await sickleaveApi.postImage(body);

    const textAnnotations = response.responses[0];
    if (textAnnotations.error) {
      return { error: textAnnotations.error.message, file: file.name };
    }
    let sickleaveDates;
    try {
      sickleaveDates = getDates(textAnnotations.textAnnotations);
    } catch (e) {
      return { error: 'Get Dates:' + e.message, file: file.name };
    }

    if (sickleaveDates.length === 0) {
      return {
        error: 'No dates found',
        file: file.name,
      };
    }
    const doc_id = uuidv4();
    const filename = `sairaslomatodistus_${user.first_name}_${user.last_name}_${sickleaveDates[0]}_${sickleaveDates[1]}_${doc_id}`;
    await sickleaveApi.uploadFileApi(base64t, filename);
    const currentID = await getSickleaveCurrentID();
    const id = currentID + 1;
    const data = {
      custom_id: id,
      user_id: user.id,
      start_date: getDateByString(sickleaveDates[0]),
      end_date: getDateByString(sickleaveDates[1]),
      type: 'sickleave',
      accepted: false,
      processed: false,
      time_stamp: new Date(),
      status: 'pending',
      filename: filename,
      accepted_time_stamp: null,
      accepted_by: null,
      description: '',
      reason: '',
    };

    await sickleaveApi.postAbsence(data);
    await updateSickleaveCurrentID(id);

    const to_users = [10, 19];
    const notificationData = {
      createdAt: new Date(),
      description: `Is-sick`,
      type: 'sickleave',
      link_to: `sickleave/list`,
      isRead: false,
      title: `${user.first_name} ${user.last_name}`,
    };

    to_users.forEach(async (to_user) => {
      notificationData.user_id = to_user;
      await createNotification(notificationData);
    });

    return { success: 'Success' };
  } catch (e) {
    return { error: e.message, file: file.name };
  }
};
