import PropTypes from 'prop-types';
import {
  Stack,
  InputAdornment,
  TextField,
  Autocomplete,
  Button,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
// components
import Iconify from '../../../../components/iconify';

// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

SickleaveTableToolbar.propTypes = {
  isFiltered: PropTypes.bool,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onResetFilter: PropTypes.func,
  filterService: PropTypes.string,
  onFilterEndDate: PropTypes.func,
  onFilterService: PropTypes.func,
  onFilterStartDate: PropTypes.func,
  filterEndDate: PropTypes.instanceOf(Date),
  filterStartDate: PropTypes.instanceOf(Date),
  optionsService: PropTypes.arrayOf(PropTypes.string),
};

export default function SickleaveTableToolbar({
  isFiltered,
  filterName,
  onFilterName,
  filterEndDate,
  onResetFilter,
  optionsReasons,
  filterReason,
  filterStartDate,
  onFilterEndDate,
  onFilterStartDate,
  onFilterReason,
}) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      <Autocomplete
        id="sickleave-reason"
        autoHighlight
        value={filterReason}
        options={optionsReasons}
        onChange={(event, newValue) => {
          onFilterReason(newValue);
        }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        sx={{ width: 1, maxWidth: { md: INPUT_WIDTH } }}
        renderInput={(params) => <TextField {...params} label="Reason" />}
      />

      <DatePicker
        label="Start date"
        value={filterStartDate}
        onChange={onFilterStartDate}
        inputFormat="dd.MM.yyyy"
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={{
              maxWidth: { md: INPUT_WIDTH },
            }}
          />
        )}
      />

      <DatePicker
        label="End date"
        value={filterEndDate}
        onChange={onFilterEndDate}
        inputFormat="dd.MM.yyyy"
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={{
              maxWidth: { md: INPUT_WIDTH },
            }}
          />
        )}
      />

      <TextField
        fullWidth
        value={filterName}
        onChange={onFilterName}
        placeholder="Search by name or reason"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          Clear
        </Button>
      )}
    </Stack>
  );
}
