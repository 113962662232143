import {
  getDoc,
  doc,
  addDoc,
  updateDoc,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';
import {
  statisticsCollectionRef,
  sickleaveReasonsCollectionRef,
} from '../db/firestore';

import { formatDoc, formatDocs } from '../utils/formatFirestore';
export const getSickleaveCurrentID = async () => {
  const docRef = doc(statisticsCollectionRef, 'sickleaveId');
  const response = await getDoc(docRef);
  return formatDoc(response).currentNumber;
};

export const updateSickleaveCurrentID = async (currentNumber) => {
  const docRef = doc(statisticsCollectionRef, 'sickleaveId');
  await updateDoc(docRef, { currentNumber });
  return;
};

export const getSickLeaveReasons = async () => {
  const response = await getDocs(sickleaveReasonsCollectionRef);
  return formatDocs(response);
};

export const createSickLeaveReason = async (data) => {
  const response = await addDoc(sickleaveReasonsCollectionRef, data);
  return response.id;
};

export const deleteSickLeaveReason = async (id) => {
  await deleteDoc(doc(sickleaveReasonsCollectionRef, id));
  return;
};
