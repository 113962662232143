import { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useTranslation } from 'react-i18next';

import CompletedShifts from './CompletedShifts';
import SummaryShifts from './SummaryShifts';

export default function WorksStatus() {
  const { t } = useTranslation();
  const [value, setValue] = useState('summary');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box className="mt-5">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label={t('report.summary')} value="summary" />
            <Tab label={t('common.done')} value="done" />
          </TabList>
        </Box>
        <TabPanel value="summary" sx={{ padding: 0, paddingTop: 3 }}>
          <SummaryShifts />
        </TabPanel>
        <TabPanel value="done" sx={{ padding: 0, paddingTop: 3 }}>
          <CompletedShifts />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
